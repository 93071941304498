import { START_SESSION, CLOSE_SESSION } from "../actionType";

const INIT_STATE = {
  uuid: "",
  name: "",
  role: "",
  configSettings: {
    notifications: true
  },
  status: "",
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_SESSION:
      state.uuid = action.user.uuid;
      state.name = action.user.names;
      state.role = action.user.role;
      state.status = action.user.status;
      return {
        ...state
      };
    case CLOSE_SESSION:
      state.uuid = "";
      state.name = "";
      state.role = "";
      state.status = "";
      return {
        ...state
      };
    default:
      return state;
  }
};
