import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { API_PURCHASES } from "../redux/apiRoutes";
import axios from '../axiosConfig.js';

import { jsPDF } from "jspdf";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { File, Printer } from "react-feather";
import { estado } from "../utils/functions";

const Purchase = () => {
  const [currentPurchase, setcurrentPurchase] = useState(null);

  const [getParamsUrl, setgetparamsUrl] = useState(false);
  const url = new URL((typeof document === "undefined") ? 'http://localhost' : document.URL);
  const navigate = useNavigate();

  const setURLParams = () => {
    if(url.searchParams.get("uuid")){
      getQuoteData(url.searchParams.get("uuid"));
    }else{
      navigate('/inicio');
    }
  }

  const getQuoteData = async (uuid,type) => {
    await axios.get(`${API_PURCHASES}?uuid=${uuid}`).then(response => {
      if(response.data.docs.length>0){
        setcurrentPurchase(response.data.docs[0]);
      }else{
        navigate('/inicio');
      }
    }).catch(e => {
      console.log(e);
      //navigate('/inicio');
    });
  }

  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    if(!getParamsUrl){
        setURLParams();
        setgetparamsUrl(true);
    }
  }, [getParamsUrl,setURLParams,setgetparamsUrl]);

  return (
    <div className="page-wrapper" style={{background:'#444'}}>
      <Container fluid={true} className="p-0">
        <div className="m-0">
          <Row>
            <Col md="12" style={{minWidth:700}}>
            {currentPurchase !== null ? 
              <CardBody>
                <div className="mt-4 mb-4 cont text-center b-light">
                  <Row className="mb-3">
                    <Col md="3" xs="3">
                      <img src={require("../assets/images/logo/logo-ciesa.png")} alt="Ciesa" width={150} />
                    </Col>
                    <Col md="9" xs="9">
                      <h4 className="font-weight-bold">ORDEN DE COMPRA No. {currentPurchase.purchaseId}</h4>
                      <table border={0} style={{width:'100%'}}>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Fecha:</td>
                          <td style={{border:'1px solid black'}}>{currentPurchase.createdAt.split('T')[0]}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Proveedor:</td>
                          <td style={{border:'1px solid black'}}>{currentPurchase.supplier[0].name}</td>
                        </tr>
                        <tr>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Atención:</td>
                          <td style={{border:'1px solid black'}}>{currentPurchase.contact}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row className="mb-3 p-4">
                    <table border={1}>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>No. PARTE</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">DESCRIPCION</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">CANTIDAD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">PRECIO UNITARIO USD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">TOTAL USD</td>
                      </tr>
                      {currentPurchase.parts !== null && currentPurchase.parts !== undefined ?
                      currentPurchase.parts.map(item =>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>{item.key}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">{item.description}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">{item.qty}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">${item.price.toFixed(2)}</td>
                          <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">${(item.price * item.qty).toFixed(2)}</td>
                        </tr>)
                      : null }
                      <tr>
                        <td colSpan={4} style={{border:'1px solid black', padding:8, fontSize:12, textAlign:'right'}} className="font-weight-bold">TOTAL USD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12, fontWeight:'bold'}} className="font-weight-bold">${currentPurchase.total.toFixed(2)}</td>
                      </tr>
                    </table>
                  </Row>
                  <Row className="mb-3 p-3">
                    <p style={{fontWeight:'bold'}}>Anotaciones: <br/>{currentPurchase.publicNotes}</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h4>DEPARTAMENTO DE COMPRAS</h4>
                  </Row>
                </div>
              </CardBody>
            : null }
            </Col>
          </Row>
        </div>

        <div className="hidePrint" onClick={handlePrint} style={{position: 'fixed', fontWeight:'bold', background: 'black', padding: 15, borderRadius: 50, color: 'white', width: 60, height:60, right: 30, bottom: 30, cursor: 'pointer', zIndex:100}}>
          <Printer size={30} />
        </div>
      </Container>
    </div>
  );
};

export default Purchase;
