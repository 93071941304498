export const getFormatDate = (dt) => {
    const date = new Date(dt);

    return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`
}

export const getFormatMoney = (mn) => {
    if(mn !== null && mn !== undefined){
        const money = Number(mn);
        
        return `$${money.toLocaleString()}`;
    }else{
        return `$${0}`;
    }
}

export const estado = (status) =>{
    switch(status){
      case 'CANCELLED':
        return <div><span className="badge badge-danger">CANCELADO</span></div>;
      case 'DONE':
        return <div><span className="badge badge-secondary">REALIZADO</span></div>;
      case 'SENT':
        return <div><span className="badge badge-warning">ENVIADO</span></div>;
      case 'WORKING':
        return <div><span className="badge badge-dark">EN PROCESO</span></div>;
      case 'WAITING':
        return <div><span className="badge badge-info">EN ESPERA</span></div>;
      case 'FINISHED':
        return <div><span className="badge badge-success">ENTREGADO</span></div>;
    }
  }

  export const quoteEstado = (status) =>{
    switch(status){
      case 'CANCELLED':
        return <div><span className="badge badge-danger">CANCELADA</span></div>;
      case 'DONE':
        return <div><span className="badge badge-secondary">APROBADA</span></div>;
      case 'SENT':
        return <div><span className="badge badge-warning">ENVIADA</span></div>;
      case 'WORKING':
        return <div><span className="badge badge-dark">EN PRODUCCIÓN</span></div>;
      case 'PENDING':
        return <div><span className="badge badge-info">EN REVISIÓN</span></div>;
      case 'WAITING':
        return <div><span className="badge badge-info">POR ACEPTAR CLIENTE</span></div>;
      case 'FINISHED':
        return <div><span className="badge badge-success">ENTREGADA</span></div>;
    }
  }

  export const purchaseEstado = (status) =>{
    switch(status){
      case 'CANCELLED':
        return <div><span className="badge badge-danger">CANCELADA</span></div>;
      case 'WORKING':
        return <div><span className="badge badge-dark">PARCIALMENTE SURTIDA</span></div>;
      case 'PENDING':
        return <div><span className="badge badge-info">EN REVISIÓN</span></div>;
      case 'WAITING':
        return <div><span className="badge badge-info">POR ACEPTAR PROVEEDOR</span></div>;
      case 'FINISHED':
        return <div><span className="badge badge-success">COMPLETADA</span></div>;
    }
  }

  export const warrantyEstado = (status) =>{
    switch(status){
      case 'CANCELLED':
        return <div><span className="badge badge-danger">CANCELADA</span></div>;
      case 'TRANSIT':
        return <div><span className="badge badge-dark">EN TRANSITO</span></div>;
      case 'PENDING':
        return <div><span className="badge badge-info">POR APROBAR</span></div>;
      case 'APPROVED':
        return <div><span className="badge badge-info">APROBADA</span></div>;
      case 'FINISHED':
        return <div><span className="badge badge-success">COMPLETADA</span></div>;
    }
  }

export const estadoPago = (status) =>{
    switch(status){
      case 'PAYED':
        return <div><span className="badge badge-success">PAGADO</span></div>;
      case 'WAITING_PAYMENT':
        return <div><span className="badge badge-warning">POR PAGAR</span></div>;
    }
  }

export const metodoPago = (status) =>{
    switch(status){
      case 'CASH':
        return <div><span className="badge badge-dark">EFECTIVO</span></div>;
      case 'TRANSFER':
        return <div><span className="badge badge-dark">TRANSFERENCIA</span></div>;
      case 'CARD':
        return <div><span className="badge badge-dark">TARJETA</span></div>;
    }
  }