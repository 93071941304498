import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row, TabContent, TabPane, Nav,
  NavItem,
  NavLink,
  Alert, } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from '../../../axiosConfig.js';
import { API_ITEM, API_PURCHASES, API_SUPPLIER, API_USER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";
import { Typeahead } from "react-bootstrap-typeahead";
import { purchaseEstado } from "../../../utils/functions";

const PurchaseOrders = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinOrder(false); clearModal();};  
  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState("1");

  const clearModal = ()=> {
    setSupplierSelected([]);
    setUserSelected([]);
    setParts([]);
    setetaDate(null);
    setsubTotal(0);
    settotal(0);
    setstatusPurchase('PENDING');
    setPrimarycolorLineTab("1");
    setCurrentPurchase({});
  }

  const [subTotal, setsubTotal] = useState(0);
  const [total, settotal] = useState(0);

  const [statusPurchase, setstatusPurchase] = useState('PENDING');
  const [etadate, setetaDate] = useState(null);
  const handleChangeEtaDate = (date) => {
    setetaDate(date);
  };

  const [userSelected,setUserSelected] = useState([]);
  const [dataUsers,setdataUsers] = useState([]);
  const getUsersList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&role=GERENTE,ADMIN`).then(response => {
      if(response.data.docs.length>0){
        const users = [];
        response.data.docs.map(user=>{
          const addUser = {
            uuid: user.uuid,
            name: user.name,
            eventAmount: user.eventAmount,
          }
          users.push(addUser);
        });
        setdataUsers(users);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [supplierSelected,setSupplierSelected] = useState([]);
  const [dataSuppliers,setdataSuppliers] = useState([]);
  const getSupplierList = async () => {
    await axios.get(`${API_SUPPLIER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const suppliers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
          }
          suppliers.push(addCustomer);
        });
        setdataSuppliers(suppliers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Proveedores: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataPurchaseList, setDataPurchaseList] = useState([]);
  const [isGetPurchaseList, setIsGetPurchaseList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getPurchaseOrders = async () => {
    setIsGetPurchaseList(true);

    let queryRule = '';
    if(user.role==='ALMACEN' || user.role ==='SISTEMAS' || user.role ==='ADMIN'){
      setEditPermissions(true);
      queryRule = ``;
    }
    
    await axios.get(`${API_PURCHASES}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataPurchaseList(response.data.docs);
      }else{
        setDataPurchaseList([]);
      }
      setIsGetPurchaseList(false);
    }).catch(e => {
      setIsGetPurchaseList(false);
      toast.error("No se pudo obtener el listado de Ordenes de Compra: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const estado = (status) =>{
    switch(status){
      case 'ACTIVE':
        return <div><span className="badge badge-success">Activo</span></div>;
      case 'DISABLED':
        return <div><span className="badge badge-warning">Desactivado</span></div>;
    }
  }

  const columns = [
    {
      name: "No. Orden",
      selector: (row) => <b>{row.purchaseId}</b>,
      sortable: true,
      width: '170px'
    },
    {
      name: "Proveedor",
      selector: (row) => <b>{row.supplier !== null && row.supplier !== undefined ? row.supplier[0].name : ''}</b>,
      sortable: true,
      width: '170px'
    },
    // {
    //   name: "Fecha Estimada",
    //   selector: (row) => <b>{row.eta !== null && row.eta !== undefined ? row.eta.split('T')[0] : ''}</b>,
    //   sortable: true,
    //   width: '220px'
    // },
    {
      name: "Estado",
      selector: (row) => <b>{purchaseEstado(row.status)}</b>,
      sortable: true,
      width: '220px',
      wrap: true,
    },
    {
      name: "Creada",
      selector: (row) => <b>{row.createdAt.split("T")[0]}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Total",
      selector: (row) => <b>${row.total !== null && row.total !== undefined ? row.total.toFixed(2) : ''}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                            {editPermissions?
                            <>
                              <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button>
                            </> : null}
                        </div>,
      width: '130px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [savingPurchase, setSavePurchase] = useState(false);
  const savePurchase = async (data) => {
    setSavePurchase(true);
    if (data !== "") {
      data.uuid = uuidv4();

      if(supplierSelected.length<=0){
        toast.error("Asigna un proveedor", {position: 'bottom-right', style:{color:'white'}});
        setSavePurchase(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSavePurchase(false);
        return;
      }

      if(etadate === null || etadate === ""){
        toast.error("Asigna una fecha estimada de entrega", {position: 'bottom-right', style:{color:'white'}});
        setSavePurchase(false);
        return;
      }

      if(parts.length<1){
        toast.error("Asigna por lo menos un producto a la orden de compra", {position: 'bottom-right', style:{color:'white'}});
        setSavePurchase(false);
        return;
      }

      data.supplier = supplierSelected;
      data.owner = userSelected;
      data.eta = etadate !== null ? etadate : null;
      data.subTotal = subTotal;
      data.total = total;
      data.parts = parts;

      await axios.post(`${API_PURCHASES}`,data).then(response => {
        if(response.data){
          toggle();
          getPurchaseOrders();
          reset();
          toast.success("¡Orden de compra creada con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setSavePurchase(false);
      }).catch(e => {
        setSavePurchase(false);
        toast.error("No se pudo crear la orden de compra: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentPurchase, setCurrentPurchase] = useState({});
  const handleEditAction = (purchase) => {
    toggle();
    setEditinOrder(true);
    setCurrentPurchase(purchase);

    if(purchase.owner !== null && purchase.owner !== undefined){
      setUserSelected(purchase.owner);
    }

    if(purchase.supplier !== null && purchase.supplier !== undefined){
      setSupplierSelected(purchase.supplier);
    }

    if(purchase.eta !== null && purchase.eta !== undefined){
      setetaDate(new Date(purchase.eta));
    }

    if(purchase.parts !== null && purchase.parts !== undefined){
      setParts(purchase.parts);
    }

    setstatusPurchase(purchase.status);
    setsubTotal(purchase.subTotal);
    settotal(purchase.total);
    
    setTimeout(()=>{
      setValue('purchaseId', purchase.purchaseId);
      setValue('contact', purchase.contact);
      setValue('status', purchase.status);
      setValue('notes', purchase.notes);
      setValue('publicNotes', purchase.publicNotes);
    },500)
  }

  const [isEditingOrder, setEditinOrder] = useState(false);
  const [patchingPurchase, setPatchingPurchase] = useState(false);
  const patchPurchase = async (data) => {
    setPatchingPurchase(true);

    if (data !== "") {
      if(supplierSelected.length<=0){
        toast.error("Asigna un proveedor", {position: 'bottom-right', style:{color:'white'}});
        setPatchingPurchase(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setPatchingPurchase(false);
        return;
      }

      if(etadate === null || etadate === ""){
        toast.error("Asigna una fecha estimada de entrega", {position: 'bottom-right', style:{color:'white'}});
        setPatchingPurchase(false);
        return;
      }

      if(parts.length<1){
        toast.error("Asigna por lo menos un producto a la orden de compra", {position: 'bottom-right', style:{color:'white'}});
        setPatchingPurchase(false);
        return;
      }

      data.supplier = supplierSelected;
      data.owner = userSelected;
      data.eta = etadate !== null ? etadate : null;
      data.subTotal = subTotal;
      data.total = total;
      data.parts = parts;

      await axios.patch(`${API_PURCHASES}/${currentPurchase.uuid}`,data).then(response => {
        if(response.data){
          getPurchaseOrders();
          setPatchingPurchase(false);
          toast.success("¡Orden de compra actualizada con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingPurchase(false);
      }).catch(e => {
        setPatchingPurchase(false);
        toast.error("No se pudo actualizar la orden de compra: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataPurchaseList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [parts, setParts] = useState([]);
  const [selectedPart, setselectedPart] = useState(null);
  const [partLoading, setPartLoading] = useState(false);
  const [partKeyValue,setpartKeyValue] = useState('');
  const getPartKey = async (value) => {
    setpartKeyValue(value);

    if(value===''){
      setselectedPart(null);
      return;
    }
    setPartLoading(true);

    await axios.get(`${API_ITEM}?country=MX&key=${encodeURIComponent(value)}`).then(async response => {
      if(response.data.docs.length>0){            
        const part = {
          uuid: response.data.docs[0].uuid,
          key: response.data.docs[0].key,
          description: response.data.docs[0].description,
          qty: 0,
          price: 0,
          total: 0,
        }
        setselectedPart(part);
      }else{
        setselectedPart(null);
      }
      setPartLoading(false);
    }).catch(e => {
      setPartLoading(false);
    });
  }

  const addPartNumber = () => {
    let temporalParts = [];
    parts.map((value)=>{
      temporalParts.push(value);
    });
    temporalParts.push(selectedPart);

    setParts(temporalParts);
    setselectedPart(null);
    setpartKeyValue('');
  }

  const deleteItem = (uuid) => {
    let temporalParts = [];
    parts.map((value)=>{
      if(value.uuid!==uuid)
        temporalParts.push(value);
    });

    setParts(temporalParts);
  }

  const calculatetotalProduct = (type,value,uuid) => {
    let temporalParts = [];
    if(value===""){
      parts.map((part)=>{
        if(part.uuid===uuid){
          if(type==="qty"){
            part.qty = "";
          }
          if(type==="price"){
            part.price = "";
          }
          part.total = 0;
        }
        temporalParts.push(part);
      });

      setParts(temporalParts);
    }else{
      parts.map((part)=>{
        if(part.uuid===uuid){
          if(type==="qty"){
            part.qty = Number(value);
          }
          
          if(type==="price"){
            part.price = Number(value);
          }
          part.total = Number(part.qty) * Number(part.price);
        }
        temporalParts.push(part);
      });

      setParts(temporalParts);
    }

    getSubtotal();
  }

  const getSubtotal = (tag,value) => {
    let subtotal = 0;
    parts.map((part)=>{
      if(part.total > 0)
        subtotal = subtotal + part.total;
    });
    setsubTotal(subtotal);
    settotal(subtotal);
  }

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
    ){
      navigate('/inicio');
    }
    getPurchaseOrders();
    getSupplierList();
    getUsersList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Compras" title="Ordenes de Compra"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Nueva</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  paginationRowsPerPageOptions={[50,100,200,500]}
                  noDataComponent={<b style={{padding:10}}>No se encontraron ordenes de compra</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'55%'}}>
        <ModalHeader toggle={toggle}>{isEditingOrder ? 'Editar Orden de Compra' : 'Crear Nuevo Orden de Compra' }
        {isEditingOrder ?
        <>
          <div style={{display:'flex',justifyContent:'left'}}><a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/purchase?uuid=${currentPurchase.uuid}`} target="_blank">Ver Documento</a></div>
          </>
        : null}
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
          <h5>Resumen</h5>
            <div className="container mt-3 mb-3 border p-2">
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Proveedor
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {
                      supplierSelected.length > 0 ?
                        supplierSelected[0].name
                      : <Alert color="warning" className="p-1 m-0">Sin elegir</Alert>
                    }
                  </Label>
                </Col>
              </Row>
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    Estado
                  </Label>
                </Col>
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {purchaseEstado(statusPurchase)}
                  </Label>
                </Col>
              </Row>
              <Row className="flex d-flex" md="12">
                <Col md="6">
                  <Label className="form-label font-weight-bold">
                    {parts.length} Productos
                  </Label>
                </Col>
                <Col md="6" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                  ${subTotal}
                  </Label>
                </Col>
              </Row>
              <hr/>
              <Row className="flex d-flex" md="12">
                <Col md="9" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    Gran Total USD
                  </Label>
                </Col>
                <Col md="3" style={{textAlign:'right'}}>
                  <Label className="form-label font-weight-bold">
                    ${total}
                  </Label>
                </Col>
              </Row>
            </div>
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingOrder ? patchPurchase : savePurchase)} style={{padding:20}}>
              <Nav className="nav-primary" tabs>
                <NavItem>
                  <NavLink
                    style={{cursor:'pointer'}}
                    className={PrimarycolorLineTab === "1" ? "active" : ""}
                    onClick={() => setPrimarycolorLineTab("1")}
                  >
                    <i className="icofont icofont-list"></i>Detalle
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{cursor:'pointer'}}
                    className={PrimarycolorLineTab === "2" ? "active" : ""}
                    onClick={() => setPrimarycolorLineTab("2")}
                  >
                    <i className="icofont icofont-box"></i>Productos
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={PrimarycolorLineTab}>
                <TabPane className="fade show" tabId="1">
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="purchaseIds">
                          No. Orden
                        </Label>
                        <input className="form-control" id="purchaseIds" type="text" placeholder="No. Orden de Compra" name="purchaseId" {...register("purchaseId", {required: true})} />
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Proveedor
                        </Label>
                        <Typeahead
                          id="suppliers"
                          clearButton
                          defaultSelected={supplierSelected}
                          labelKey="name"
                          options={dataSuppliers}
                          placeholder="Buscar Proveedor..."
                          onChange={(e)=>{setSupplierSelected(e)}}
                        />
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="contacts">
                          Contacto
                        </Label>
                        <input className="form-control" id="contacts" type="text" placeholder="Persona de Contacto" name="contact" {...register("contact", {required: true})} />
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Estado
                        </Label>
                        <select defaultValue={statusPurchase} name="status" {...register("status", { required: true })} className="form-control" onChange={(e)=>setstatusPurchase(e.target.value)}>
                          <option value="PENDING">EN REVISIÓN</option>
                          <option value="WAITING">POR ACEPTAR PROVEEDOR</option>
                          <option value="WORKING">PARCIALMENTE SURTIDA</option>
                          <option value="FINISHED">COMPLETADA</option>
                          <option value="CANCELLED">CANCELADA</option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Fecha Estimada de Entrega
                        </Label>
                        <DatePicker className="form-control digits"
                            selected={etadate}
                            onChange={handleChangeEtaDate}
                            selectsStart
                            locale={es}
                            dateFormat={'dd/MM/yyyy h:mm aa'}
                            showTimeSelect
                          />
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Realiza
                        </Label>
                        <Typeahead
                          id="users"
                          clearButton
                          defaultSelected={userSelected}
                          labelKey="name"
                          options={dataUsers}
                          placeholder="Buscar Usuarios..."
                          onChange={(e)=>{setUserSelected(e)}}
                        />
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="notes">
                          Notas Internas
                        </Label>
                        <textarea className="form-control" id="notes" placeholder="Notas" name="notes" {...register("notes")} ></textarea>
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="notes">
                          Notas para el Proveedor
                        </Label>
                        <textarea className="form-control" id="publicNotes" placeholder="Notas" name="publicNotes" {...register("publicNotes")} ></textarea>
                      </Col>
                    </Row>
                </TabPane>
                <TabPane className="fade show" tabId="2">
                  <Row className="g-3 dflex mb-3">
                    <Col md="12">
                      <input value={partKeyValue} onChange={(e)=>getPartKey(e.target.value)} className="form-control" type="text" placeholder="Buscar número de parte" />
                      {
                        partLoading ? 'Bucando...' :
                          selectedPart!== null ?
                          <Button onClick={addPartNumber} className="mt-2"><i className="fa fa-plus"></i> Agregar</Button> : partKeyValue !== '' ? <p className="mt-1" style={{color:'red'}}>No encontrado</p> : null
                      }
                    </Col>
                  </Row>
                  {parts.length > 0 ?
                    parts.map(part=>{
                      return(
                        <Row key={part.uuid} className="g-3 dflex mb-3 p-2 mt-1" style={{backgroundColor:'#f1f1f1'}}>
                          <div style={{color:'white', background:'red', position:'absolute', right:60, marginTop:0-10, padding:5, borderRadius:5, width:20, textAlign:'center', cursor:'pointer'}} onClick={()=>deleteItem(part.uuid)}>X</div>
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              No. Parte: {part.key}
                            </Label><br/>
                            <Label className="form-label font-weight-bold">
                              Descripción: <b style={{color:'green'}}>{part.description}</b>
                            </Label><br/>
                            <Label className="form-label font-weight-bold" for="validQty">
                              Cantidad: <input className="form-control" type="number" placeholder="Cantidad" value={part.qty} onChange={(e)=>{calculatetotalProduct('qty',e.target.value, part.uuid);}} />
                            </Label>
                            <br/>
                            <Label className="form-label font-weight-bold" for="validQty">
                              Precio: $ 
                              <input className="form-control" type="number" placeholder="Cantidad" value={part.price} onChange={(e)=>{calculatetotalProduct('price',e.target.value, part.uuid);}} />
                            </Label><br/>
                            <Label className="form-label font-weight-bold" for="validQty">
                              Total: ${part.total}
                            </Label>
                          </Col>
                        </Row>
                      )
                    })
                  : null }
                </TabPane>
              </TabContent>

              <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                <span style={{color:'red'}}>{errors.purchaseId && "- No. Orden Requerido"}</span>
                <span style={{color:'red'}}>{errors.contact && "- Persona de Contacto Requerida"}</span>
                {
                  isEditingOrder ? 
                    <Button disabled={patchingPurchase} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                  : 
                    <Button disabled={savingPurchase} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                }
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default PurchaseOrders;