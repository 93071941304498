import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { API_SUPPLIER } from "../../../redux/apiRoutes";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";

import { useSelector } from "react-redux";
import axios from '../../../axiosConfig.js';
import { useMemo } from "react";
import FilterComponent from "../../common/filter";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";

function Suppliers(props) {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState(false);
  const [dataUsers, setDataUsers] = useState([]);

  const toggle = () => {setModal(!modal); reset(); setEditingSupplier(false);};

  const [getSuppliers, setGetSuppliers] = useState(false);
  const getSuppliersList = async () => {
    setGetSuppliers(true);

    await axios.get(`${API_SUPPLIER}`).then(response => {
      if(response.data.docs.length>0){
        setDataUsers(response.data.docs);
      }
      setGetSuppliers(false);
    }).catch(e => {
      setGetSuppliers(false);
      toast.error("No se pudo obtener el listado de Proveedores: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [isEditingSupplier, setEditingSupplier] = useState(false);
  const [supplierData, setsupplierData] = useState(false);
  const handleViewSupplier = (supplier) => {
    toggle();
    setEditingSupplier(true);
    setsupplierData(supplier);

    setTimeout(()=>{
      setValue('name', supplier.name);
      setValue('contact', supplier.contact);
      setValue('email', supplier.email);
      setValue('phone', supplier.phone);
      setValue('address', supplier.address);
      setValue('city', supplier.city);
      setValue('state', supplier.state);
      setValue('charge', supplier.charge);
    },500)
  }

  const handleDeleteSupplier = (user) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminara el proveedor: ${user.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_SUPPLIER}/${user.uuid}`).then(response => {
          getSuppliersList();
          SweetAlert.fire("¡Listo!", "proveedor eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar productos: " + error
            );
          }, 200);
        });
      }
    });
  }

  const columns = [
    {
      name: "Nombre",
      selector: (row) => <b>{row.name}</b>,
      sortable: true,
      center: true,
      width: '200px'
    },
    {
      name: "Email",
      selector: (row) => row.email,
      center: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row.phone,
      center: true,
    },
    {
      name: "Creado",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          { user.role === 'GERENTE' && (row.role !== 'ADMIN' && row.role !== 'SISTEMAS' && row.role !== 'GERENTE') ?
                            <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewSupplier(row)}}><i className="fa fa-pencil"></i></Button> : null }
                          { user.role === 'ADMIN' || user.role === 'SISTEMAS' ?
                          <>
                            <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewSupplier(row)}}><i className="fa fa-pencil"></i></Button>
                            <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleDeleteSupplier(row)}}><i className="fa fa-trash"></i></Button>
                          </>
                          : null }
                        </div>,
      center: true,
      width: '180px'
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataUsers.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [creatingSupplier, setCreatingSupplier] = useState(false);
  const onSubmitCreateSupplier = async (user) => {
    setCreatingSupplier(true);

    if (user !== "") {
      user.uuid = uuidv4();
      user.status = 'ACTIVE';

      await axios.post(`${API_SUPPLIER}`,user).then(response => {
        if(response.data){
          toggle();
          getSuppliersList();
          reset();
          toast.success("Proveedor creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setCreatingSupplier(false);
      }).catch(e => {
        setCreatingSupplier(false);
        toast.error("No se pudo crear el Proveedor: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [patchingSupplier, setPatchingSupplier] = useState(false);
  const onSubmitPatchSupplier = async (user) => {
    setPatchingSupplier(true);

    if (user !== "") {
      await axios.patch(`${API_SUPPLIER}/${supplierData.uuid}`,user).then(response => {
        if(response.data){
          toggle();
          getSuppliersList();
          reset();
          toast.success("Proveedor actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingSupplier(false);
      }).catch(e => {
        setPatchingSupplier(false);
        toast.error("No se pudo actualizar el Proveedor: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  useEffect(() => {
    getSuppliersList();
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
    ){
      navigate('/inicio');
    }
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Administrar" title="Proveedores" />
      
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Nuevo</Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                {
                  getSuppliers ?
                    <div className="loader-box">
                      <div className="loader-1"></div>
                    </div>
                  :
                    <DataTable
                      columns={columns}
                      striped={true}
                      center={true}
                      data={filteredItems}
                      pagination
                      subHeader
                      subHeaderComponent={subHeaderComponent}
                      noDataComponent={<b style={{padding:10}}>No se encontraron Proveedores</b>}
                    />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'50%'}}>
        <ModalHeader toggle={toggle}>{isEditingSupplier ? 'Editar Proveedor' : 'Crear Nuevo Proveedor' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingSupplier ? onSubmitPatchSupplier : onSubmitCreateSupplier)} style={{padding:20}}>
            <Row className="g-3 dflex mb-3">
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom01">
                  Empresa
                </Label>
                <input className="form-control" id="validationCustom01" type="text" placeholder="Nombre" name="name" {...register("name", { required: true })} />
                <span>{errors.name && "Nombre(s) requerido"}</span>
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom01">
                  Contacto (Persona)
                </Label>
                <input className="form-control" id="validationCustom01" type="text" placeholder="Contacto" name="contact" {...register("contact", { required: true })} />
                <span>{errors.contact && "Nombre(s) requerido"}</span>
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom06">
                  Telefono
                </Label>
                <input className="form-control" id="validationCustom06" type="text" placeholder="Telefono" name="phone" {...register("phone", { required: true })} />
                <span>{errors.phone && "Telefono requerido"}</span>
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom03">
                  Email
                </Label>
                <input className="form-control" id="validationCustom03" type="email" placeholder="Email" name="email" {...register("email", { required: true })} />
                <span>{errors.email && "Email requerido"}</span>
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom07">
                  Dirección
                </Label>
                <input className="form-control" id="validationCustom07" type="text" placeholder="Dirección" name="address" {...register("address")} />
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom08">
                  Ciudad
                </Label>
                <input className="form-control" id="validationCustom08" type="text" placeholder="Ciudad" name="city" {...register("city")} />
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom09">
                  Estado
                </Label>
                <input className="form-control" id="validationCustom09" type="text" placeholder="Estado" name="state" {...register("state")} />
              </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
              {
                isEditingSupplier ? 
                  <Button disabled={patchingSupplier} className="btn btn-primary" style={{width:200}} color="primary">{"Guardar"}</Button> 
                : 
                  <Button disabled={creatingSupplier} className="btn btn-primary" style={{width:200}} color="primary">{"Crear"}</Button> 
              }
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default Suppliers;
