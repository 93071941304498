import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { API_SERVICE } from "../redux/apiRoutes";
import axios from '../axiosConfig.js';

import { jsPDF } from "jspdf";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { File, Printer } from "react-feather";
import { estado } from "../utils/functions";

const Pediment = () => {
  const [currentService, setcurrentService] = useState(null);

  const [getParamsUrl, setgetparamsUrl] = useState(false);
  const url = new URL((typeof document === "undefined") ? 'http://localhost' : document.URL);
  const navigate = useNavigate();

  const setURLParams = () => {
    if(url.searchParams.get("type") && url.searchParams.get("uuid")){
      getServiceData(url.searchParams.get("uuid"),url.searchParams.get("type"));
    }else{
      navigate('/inicio');
    }
  }

  const getServiceData = async (uuid,type) => {
    await axios.get(`${API_SERVICE}?uuid=${uuid}&type=${type}`).then(response => {
      if(response.data.docs.length>0){
        setcurrentService(response.data.docs[0]);
      }else{
        navigate('/inicio');
      }
    }).catch(e => {
      console.log(e);
      //navigate('/inicio');
    });
  }

  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    if(!getParamsUrl){
        setURLParams();
        setgetparamsUrl(true);
    }
  }, [getParamsUrl,setURLParams,setgetparamsUrl]);

  return (
    <div className="page-wrapper" style={{background:'#444'}}>
      <Container fluid={true} className="p-0">
        <div className="m-0">
          <Row id="pdfDownload">
            <Col md="12" style={{minWidth:700}}>
            {currentService !== null ? 
              <CardBody>
                <div className="mt-4 mb-4 cont text-center b-light">
                  <Row className="mb-3">
                    <Col md="3" xs="3">
                      <img src={require("../assets/images/logo/logo-ciesa.png")} alt="Ciesa" width={150} />
                    </Col>
                    <Col md="9" xs="9">
                      <h4 className="font-weight-bold">REPORTE DE PEDIMENTOS</h4>
                      <table border={0} style={{width:'100%'}}>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Orden de Compra:</td>
                          <td style={{border:'1px solid black'}}>{currentService.eventId}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Cliente:</td>
                          <td style={{border:'1px solid black'}}>{currentService.customer[0].name}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Fecha:</td>
                          <td style={{border:'1px solid black'}}>{currentService.createdAt.split('T')[0]}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Fecha Embarque:</td>
                          <td style={{border:'1px solid black'}}>{}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row className="mb-3 p-4">
                    <table border={1}>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black'}}>NO. PARTE</td>
                        <td style={{border:'1px solid black'}} className="font-weight-bold">DESCRIPCIÓN</td>
                        <td style={{border:'1px solid black'}} className="font-weight-bold">CANTIDAD</td>
                        <td style={{border:'1px solid black'}} className="font-weight-bold">PEDIMENTO</td>
                      </tr>
                      {currentService.pediment.map((pediment,i)=>{
                        return(
                          <tr key={i}>
                            <td style={{border:'1px solid black'}}>{pediment.number}</td>
                            <td style={{border:'1px solid black'}}>{pediment.description}</td>
                            <td style={{border:'1px solid black'}}>{pediment.qty}</td>
                            <td style={{border:'1px solid black'}}>{pediment.pediment}</td>
                          </tr>
                        )
                      })}
                    </table>
                  </Row>
                  <Row className="mb-3">
                    <table border={0} style={{width:'100%', border:'none'}}>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black'}}>Hidrostatic Test:</td>
                        <td style={{border:'1px solid black', minWidth:100}}></td>
                        <td style={{border:'1px solid black'}} className="font-weight-bold">Function Test:</td>
                        <td style={{border:'1px solid black'}}></td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black'}}>Seat Leak Test:</td>
                        <td style={{border:'1px solid black', minWidth:100}}></td>
                        <td style={{border:'1px solid black'}} className="font-weight-bold">Técnico:</td>
                        <td style={{border:'1px solid black', minWidth:100}}></td>
                      </tr>
                    </table>
                  </Row>
                </div>
              </CardBody>
            : null }
            </Col>
          </Row>
        </div>

        <div className="hidePrint" onClick={handlePrint} style={{position: 'fixed', fontWeight:'bold', background: 'black', padding: 15, borderRadius: 50, color: 'white', width: 60, height:60, right: 30, bottom: 30, cursor: 'pointer', zIndex:100}}>
          <Printer size={30} />
        </div>
      </Container>
    </div>
  );
};

export default Pediment;
