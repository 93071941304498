export const API_USER = `${process.env.REACT_APP_MS_API_URL}/user`;
export const API_CUSTOMER = `${process.env.REACT_APP_MS_API_URL}/customer`;
export const API_SUPPLIER = `${process.env.REACT_APP_MS_API_URL}/provider`;
export const API_SERVICE = `${process.env.REACT_APP_MS_API_URL}/service`;
export const API_REPORT = `${process.env.REACT_APP_MS_API_URL}/report`;
export const API_COMPANY = `${process.env.REACT_APP_MS_API_URL}/company`;
export const API_PRODUCT = `${process.env.REACT_APP_MS_API_URL}/product`;
export const API_PURCHASES = `${process.env.REACT_APP_MS_API_URL}/purchase`;
export const API_WARRANTY = `${process.env.REACT_APP_MS_API_URL}/warranty`;
export const API_LOGISTIC = `${process.env.REACT_APP_MS_API_URL}/logistic`;
export const API_CARDEX = `${process.env.REACT_APP_MS_API_URL}/cardex`;
export const API_ITEM = `${process.env.REACT_APP_MS_API_URL}/item`;
export const API_QUOTE = `${process.env.REACT_APP_MS_API_URL}/quote`;
export const API_QUOTE_PART = `${process.env.REACT_APP_MS_API_URL}/quote-part`;
export const API_FILES = `${process.env.REACT_APP_MS_API_URL}/file`;
export const API_URL = `${process.env.REACT_APP_MS_API_ROOT_URL}`;