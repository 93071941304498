import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from '../../../axiosConfig.js';
import { API_HOSPITAL, API_ITEM, API_SURGERIE, API_USER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";

const Items = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditingItem(false); settypeSelected("VAL"); setBrand("CIESA-VLS")};

  const [modalImportCollect, setModalImportCollect] = useState(false);

  const togglemodalImportCollect = () => {
    setModalImportCollect(!modalImportCollect);
    setCollectStatus([]);
    setFileImport(null);
  };

  const [modalPrices, setModalPrices] = useState(false);
  const togglemodalPrices = () => {
    setModalPrices(!modalPrices);
    setpercentIncrement(0);
  };

  const [brand, setBrand] = useState("CIESA-VLS");
  const [typeSelected, settypeSelected] = useState("VAL");
  const [loadingImportCollect, setLoadingImportCollect] = useState(false);

  const [dataItemList, setDataItemList] = useState([]);
  const [isGetItemList, setIsGetItemList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getItemList = async () => {
    setIsGetItemList(true);

    let queryRule = '';
    if(user.role ==='SISTEMAS' || user.role ==='ADMIN'){
      setEditPermissions(true);
    }
    
    await axios.get(`${API_ITEM}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataItemList(response.data.docs);
      }else{
        setDataItemList([]);
      }
      setIsGetItemList(false);
    }).catch(e => {
      setIsGetItemList(false);
      toast.error("No se pudo obtener el listado de llaves: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const tipo = (status) =>{
    switch(status){
      case 'VAL':
        return <div>Válvula</div>;
      case 'ACT':
        return <div>Actuador</div>;
      case 'POS':
        return <div>Posicionador</div>;
      case 'PART':
        return <div>Refacción</div>;
    }
  }

  const columns = [
    {
      name: "Tipo",
      selector: (row) => <b>{tipo(row.type)}</b>,
      sortable: true,
      width: '180px'
    },
    {
      name: "Marca",
      selector: (row) => <b>{row.brand}</b>,
      sortable: true,
    },
    {
      name: "No. Llave",
      selector: (row) => <b>{row.key}</b>,
      sortable: true,
      width: '350px'
    },
    {
      name: "Descripción",
      selector: (row) => <b>{row.description}</b>,
      sortable: true,
    },
    {
      name: "Precio base USD",
      selector: (row) => <b>${row.price}</b>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                            {editPermissions?
                            <>
                              <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button>                                  
                              {/* <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleStatusProduct(row, row.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED')}}><i className="fa fa-power-off"></i></Button> */}
                            </> : null}
                        </div>,
      width: '130px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [savingItem, setSaveItem] = useState(false);
  const saveItem = async (data) => {
    setSaveItem(true);
    if (data !== "") {
      data.uuid = uuidv4();
      data.status = 'ACTIVE';
      data.type = typeSelected;
      data.brand = brand;

      await axios.post(`${API_ITEM}`,data).then(response => {
        if(response.data){
          toggle();
          getItemList();
          reset();
          toast.success("¡Llave creada con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveItem(false);
      }).catch(e => {
        setSaveItem(false);
        toast.error("No se pudo crear la llave: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentItem, setCurrentItem] = useState({});
  const handleEditAction = (item) => {
    toggle();
    setEditingItem(true);
    setCurrentItem(item);
    settypeSelected(item.type);
    setBrand(item.brand);
    
    setTimeout(()=>{
      setValue('key', item.key);
      setValue('description', item.description);
      setValue('price', item.price);
    },500)
  }

  const [isEditingItem, setEditingItem] = useState(false);
  const [patchingItem, setPatchingItem] = useState(false);
  const patchItem = async (data) => {
    setPatchingItem(true);
    data.type = typeSelected;
    data.brand = brand;

    if (data !== "") {
      await axios.patch(`${API_ITEM}/${currentItem.uuid}`,data).then(response => {
        if(response.data){
          getItemList();
          toggle();
          setPatchingItem(false);
          toast.success("¡Llave actualizada con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingItem(false);
      }).catch(e => {
        setPatchingItem(false);
        toast.error("No se pudo actualizar la Llave: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleStatusProduct = (product,status) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se ${status === 'DISABLED' ? 'desactivará' : 'activará'} el producto: ${product.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.patch(`${API_ITEM}/${product.uuid}`,{status:status}).then(response => {
          getItemList();
          SweetAlert.fire("¡Listo!", `producto ${status === 'DISABLED' ? 'desactivado' : 'activado'}`, "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el producto: " + error
            );
          }, 200);
        });
      }
    });
  }

  const escapeDoubleQuotes = (text) => text.replace(/"/g, '\\"');

  const filteredItems = dataItemList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(escapeDoubleQuotes(filterText.toLowerCase())) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [loadingImportItems, setLoadingImportItems] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [collectStatus, setCollectStatus] = useState([]);
  
  const importCollect = async () => {
    setLoadingImportItems(true);

    if (fileImport === null || fileImport === undefined) {
      toast.error("Selecciona un archivo");
      setLoadingImportItems(false);
      return
    }

    if (fileImport.type !== "text/csv") {
      toast.error("Sólo se aceptan archivos con formato CSV");
      setLoadingImportItems(false);
      return;
    }

    if (fileImport.size > 5000000) {
      toast.error("El archivo no puede pesar más de 5 MB");
      setLoadingImportItems(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', fileImport);

    axios.post(`${API_ITEM}/multiples`, formData).then(response => {
      const CollectsFailled = [];
      response.data.log.map((item) => {
        return CollectsFailled.push(item);
      });

      if(response.data.allDone){
        toast.success("¡Llaves guardadas con éxito!");
        togglemodalImportCollect();
      }else{
        toast.info('Algunas llaves no pudieron cargarse, revisa por favor')
        setCollectStatus(CollectsFailled);
      }

      getItemList()
      setLoadingImportItems(false);
    }).catch(e => {
      toast.error(e.response.data.error ? e.response.data.error : e.message,{autoClose: false});
      setLoadingImportItems(false);
    });
  }

  const [percentIncrement, setpercentIncrement] = useState(0);
  const [typeIncrement, setTypeIncrement] = useState('ALL');
  const [brandIncrement, setBrandIncrement] = useState('CIESA-VLS');

  const [loadingIncrements, setLoadingIncrements] = useState(false);  
  const saveIncrement = async () => {
    setLoadingIncrements(true);

    if(percentIncrement<=0){
      toast.error("El incremento de precios no puede ser igual o menor a 0%", {position: 'bottom-right', style:{color:'white'}});
      setLoadingIncrements(false);
      return;
    }

    if(percentIncrement>=10){
      toast.error("El incremento de precios no puede ser mayor a 10%", {position: 'bottom-right', style:{color:'white'}});
      setLoadingIncrements(false);
      return;
    }

    let descriptionIncrement = '';

    if(typeIncrement!=='ALL'){
      descriptionIncrement = ` de las llaves tipo ${typeIncrement} de la marca ${brandIncrement} en todo el sistema`;
    }else{
      descriptionIncrement = `de todas las llaves del sistema`;
    }

    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Estás a punto de actualizar ${percentIncrement}% los precios ${descriptionIncrement}, la acción es Irreversible.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        const incre = {
          percent: percentIncrement,
          type: typeIncrement,
          brand: brandIncrement,
        }

        axios.post(`${API_ITEM}/increment`, incre).then(response => {
          toast.success("¡Precios actualizados con éxito!");
          togglemodalPrices();
    
          getItemList();
          setLoadingIncrements(false);
        }).catch(e => {
          toast.error(e.response.data.error ? e.response.data.error : e.message,{autoClose: false});
          setLoadingIncrements(false);
        });
      }else{
        setLoadingIncrements(false);
      }
    });
  }

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE_OPERACIONES"
      && user.role !== "ALMACEN"
      && user.role !== "COTIZADOR"
    ){
      navigate('/inicio');
    }
    getItemList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Ventas" title="Llaves y precios"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="white" style={{marginLeft: 25, marginTop: 10}} onClick={togglemodalPrices}><i className="fa fa-money"></i> Cambiar Precios</Button>
            <Button color="white" style={{marginLeft: 10, marginTop: 10}} onClick={togglemodalImportCollect}><i className="fa fa-upload"></i> Importar Múltiples</Button>
            <Button color="primary" onClick={toggle}>Nueva Llave</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                {isGetItemList ? 'Cargando data...' :
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  paginationRowsPerPageOptions={[50,100,200,500]}
                  noDataComponent={<b style={{padding:10}}>{isGetItemList ? 'Cargando...' : 'No se encontraron llaves'}</b>}
                />}
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{isEditingItem ? 'Editar Llave' : 'Crear Nueva Llave' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingItem ? patchItem : saveItem)} style={{padding:20}}>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="categoryProd">
                    Tipo
                  </Label>
                  <select defaultValue={typeSelected} onChange={(e)=>settypeSelected(e.target.value)} className="form-control" name="category" >
                    <option value="VAL">Válvula</option>
                    <option value="ACT">Actuador</option>
                    <option value="POS">Posicionador</option>
                    <option value="PART">Refacción</option>
                  </select>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="brand">
                    Marca
                  </Label>
                  <select defaultValue={brand} value={brand} onChange={(e)=>setBrand(e.target.value)} className="form-control" name="category" >
                    <option value="CIESA-VLS">CIESA-VLS</option>
                    <option value="MASS">MASS</option>
                    <option value="BLOQUEO">BLOQUEO</option>
                  </select>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="llave">
                    Llave
                  </Label>
                  <input className="form-control" id="llave" type="text" placeholder="Llave" name="key" {...register("key")} />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="priceItem">
                    Precio
                  </Label>
                  <input className="form-control" id="priceItem" type="number" placeholder="Precio" name="price" {...register("price")} />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="descriptTx">
                    Descripción
                  </Label>
                  <textarea className="form-control" id="descriptTx" placeholder="Descripción de de la llave" name="description" {...register("description")} ></textarea>
                </Col>
              </Row>
              <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
              {
                isEditingItem ? 
                  <Button disabled={patchingItem} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                : 
                  <Button disabled={savingItem} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
              }
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalImportCollect}>
          {(collectStatus.length>0) ?
            <Fragment>
              <ModalHeader toggle={togglemodalImportCollect}>
                Archivo procesado con algunos errores
              </ModalHeader>  
              <ModalBody>
              <Alert color="light" style={{overflowY:'scroll', minHeight:220, maxHeight: 220}}>
                {
                  collectStatus.map((item) => {
                    return(
                      <div>
                        <b>{item.row}:</b> {item.error}
                      </div>
                    )
                  })
                }
              </Alert>
              </ModalBody>
              <ModalFooter>
                <Button color="primary btn-pill" onClick={togglemodalImportCollect}>
                  Listo
                </Button>
              </ModalFooter>
            </Fragment>
            :
            <Fragment>
              <ModalBody>
                <ModalHeader toggle={togglemodalImportCollect}>
                <i className="icon-files"></i> Cargar múltiples llaves de precios
                </ModalHeader>
                <div className="contain p-30">
                  <div className="text-center">
                    <Form className="theme-form">
                      <FormGroup className="row">
                        <Label
                          className="col-sm-3 col-form-label"
                        >
                          Archivo
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control btn-pill"
                            type="file"
                            onChange={(e) => setFileImport(e.target.files[0])}
                          />
                        </Col>
                      </FormGroup>
                      {/* <span style={{color:'GrayText'}}>* Puedes crear hasta 100 solicitudes desde un archivo con formato CSV <a href={require('../../assets/files/importar_solicitudes_pago.csv')} download>descargarlo aquí</a>.</span> */}
                    </Form>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="text-right">
                {loadingImportCollect ? (
                  <Button color="primary btn-pill" disabled={loadingImportCollect}>
                    Guardando...
                  </Button>
                ) : (
                  <Button color="primary btn-pill" onClick={importCollect}>
                    Importar
                  </Button>
                )}
              </ModalFooter>
            </Fragment> 
          }
        </Modal>

      <Modal isOpen={modalPrices}>
        <Fragment>
          <ModalBody>
            <ModalHeader toggle={togglemodalPrices}>
              <i className="icon-money"></i> Ajustar precios masivamente
            </ModalHeader>
            <div className="contain p-30">
              <div className="text-center">
                <Alert color={typeIncrement !== "ALL" ? "warning" : "danger"}>¡ATENCIÓN! Este ajuste de precios afectará a todas las llaves {typeIncrement !== "ALL" ? `de tipo ${typeIncrement} de la marca ${brandIncrement}` : ' existentes'}</Alert>
                <Form className="theme-form">
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label bold">
                      Tipo:
                    </Label>
                    <Col sm="9" className="flex dflex">
                      <select defaultValue={typeIncrement} value={typeIncrement} onChange={(e)=>setTypeIncrement(e.target.value)} className="form-control" name="category" >
                        <option value="ALL">TODOS</option>
                        <option value="VALVULAS">VALVULAS</option>
                        <option value="ACTUADORES">ACTUADORES</option>
                        <option value="POSICIONADORES">POSICIONADORES</option>
                        <option value="REFACCIONES">REFACCIONES</option>
                      </select>
                    </Col>
                  </FormGroup>
                  {typeIncrement !== 'ALL' ?
                  <FormGroup className="row">
                    <Label className="col-sm-3 col-form-label bold">
                      Marca:
                    </Label>
                    <Col sm="9" className="flex dflex">
                      <select defaultValue={brandIncrement} value={brandIncrement} onChange={(e)=>setBrandIncrement(e.target.value)} className="form-control" name="category" >
                        <option value="CIESA-VLS">CIESA-VLS</option>
                        <option value="MASS">MASS</option>
                        <option value="BLOQUEO">BLOQUEO</option>
                      </select>
                    </Col>
                  </FormGroup> : null }
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label bold"
                    >
                      Incremento:
                    </Label>
                    <Col sm="9" className="flex dflex">
                      <Input
                        className="form-control"
                        type="number"
                        style={{maxWidth:120}}
                        value={percentIncrement}
                        onChange={(e) => setpercentIncrement(e.target.value)}
                      /><span style={{marginTop:15, marginLeft:4, fontWeight:'bold'}}>%</span>
                    </Col>
                  </FormGroup>
                  {/* <span style={{color:'GrayText'}}>* Puedes crear hasta 100 solicitudes desde un archivo con formato CSV <a href={require('../../assets/files/importar_solicitudes_pago.csv')} download>descargarlo aquí</a>.</span> */}
                </Form>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-right">
            {loadingIncrements ? (
              <Button color="primary btn-pill" disabled={loadingIncrements}>
                Guardando... No cierres la ventana
              </Button>
            ) : (
              <Button color="primary btn-pill" onClick={saveIncrement}>
                Incrementar Precios
              </Button>
            )}
          </ModalFooter>
        </Fragment>
      </Modal>

    </Fragment>
  );
};

export default Items;