import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from '../../../axiosConfig.js';
import { API_WARRANTY, API_CUSTOMER, API_USER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';

import FilterComponent from "../../common/filter";
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";
import { Typeahead } from "react-bootstrap-typeahead";
import { purchaseEstado, warrantyEstado } from "../../../utils/functions";

const Warranty = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinWarranty(false); clearModal();};

  const clearModal = ()=> {
    setCustomerSelected([]);
    setUserSelected([]);
    setetaDate(null);
    setstatusWarranty('PENDING');
    setCurrentWarranty({});
  }

  const [statusWarranty, setstatusWarranty] = useState('PENDING');
  const [etadate, setetaDate] = useState(null);
  const handleChangeEtaDate = (date) => {
    setetaDate(date);
  };

  const [userSelected,setUserSelected] = useState([]);
  const [dataUsers,setdataUsers] = useState([]);
  const getUsersList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const users = [];
        response.data.docs.map(user=>{
          const addUser = {
            uuid: user.uuid,
            name: user.name,
            eventAmount: user.eventAmount,
          }
          users.push(addUser);
        });
        setdataUsers(users);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [customerSelected,setCustomerSelected] = useState([]);
  const [dataCustomers,setdataCustomers] = useState([]);
  const getCustomersList = async () => {
    await axios.get(`${API_CUSTOMER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const customers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
          }
          customers.push(addCustomer);
        });
        setdataCustomers(customers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Servicios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataWarrantyList, setDataWarrantyList] = useState([]);
  const [isGetPurchaseList, setIsGetWarrantyList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getWarranties = async () => {
    setIsGetWarrantyList(true);

    let queryRule = '';
    if(user.role==='ALMACEN' || user.role ==='SISTEMAS' || user.role ==='ADMIN'){
      setEditPermissions(true);
      queryRule = ``;
    }
    
    await axios.get(`${API_WARRANTY}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataWarrantyList(response.data.docs);
      }else{
        setDataWarrantyList([]);
      }
      setIsGetWarrantyList(false);
    }).catch(e => {
      setIsGetWarrantyList(false);
      toast.error("No se pudo obtener el listado de Ordenes de Compra: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const columns = [
    {
      name: "Folio",
      selector: (row) => <b>{row.folio}</b>,
      sortable: true,
      width: '170px'
    },
    {
      name: "Cliente",
      selector: (row) => <b>{row.customer !== null && row.customer !== undefined ? row.customer[0].name : ''}</b>,
      sortable: true,
    },
    {
      name: "Item",
      selector: (row) => <b>{row.item}</b>,
      sortable: true,
    },
    {
      name: "Descripción",
      selector: (row) => <b>{row.description}</b>,
      sortable: true,
    },
    // {
    //   name: "Fecha Estimada",
    //   selector: (row) => <b>{row.eta !== null && row.eta !== undefined ? row.eta.split('T')[0] : ''}</b>,
    //   sortable: true,
    //   width: '220px'
    // },
    {
      name: "Estado",
      selector: (row) => <b>{warrantyEstado(row.status)}</b>,
      sortable: true,
      width: '220px',
      wrap: true,
    },
    {
      name: "Responsable",
      selector: (row) => <b>{row.owner !== null && row.owner !== undefined ? row.owner[0].name : ''}</b>,
      sortable: true,
    },
    {
      name: "Creada",
      selector: (row) => <b>{row.createdAt.split("T")[0]}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                            {editPermissions?
                            <>
                              <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button>
                            </> : null}
                        </div>,
      width: '130px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [savingWarranty, setSaveWarranty] = useState(false);
  const saveWarranty = async (data) => {
    setSaveWarranty(true);
    if (data !== "") {
      data.uuid = uuidv4();

      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setSaveWarranty(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSaveWarranty(false);
        return;
      }

      data.customer = customerSelected;
      data.owner = userSelected;
      data.receptionDate = etadate !== null ? etadate : null;
      data.status = 'PENDING';

      await axios.post(`${API_WARRANTY}`,data).then(response => {
        if(response.data){
          toggle();
          getWarranties();
          reset();
          toast.success("¡Garantía creada con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveWarranty(false);
      }).catch(e => {
        setSaveWarranty(false);
        toast.error("No se pudo crear la Garantía: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentWarranty, setCurrentWarranty] = useState({});
  const handleEditAction = (warranty) => {
    toggle();
    setEditinWarranty(true);
    setCurrentWarranty(warranty);

    if(warranty.owner !== null && warranty.owner !== undefined){
      setUserSelected(warranty.owner);
    }

    if(warranty.customer !== null && warranty.customer !== undefined){
      setCustomerSelected(warranty.customer);
    }

    if(warranty.receptionDate !== null && warranty.receptionDate !== undefined){
      setetaDate(new Date(warranty.receptionDate));
    }

    setstatusWarranty(warranty.status);
    
    setTimeout(()=>{
      setValue('folio', warranty.folio);
      setValue('ciesaInvoice', warranty.ciesaInvoice);
      setValue('customerPurchaseOrder', warranty.customerPurchaseOrder);
      setValue('status', warranty.status);
      setValue('item', warranty.item);
      setValue('description', warranty.description);
      setValue('reason', warranty.reason);
      setValue('notes', warranty.notes);
      setValue('publicNotes', warranty.publicNotes);
      setValue('folioWarehouse', warranty.folioWarehouse);
    },500)
  }

  const [isEditingWarranty, setEditinWarranty] = useState(false);
  const [patchingPurchase, setPatchingWarranty] = useState(false);
  const patchWarranty = async (data) => {
    setPatchingWarranty(true);

    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setPatchingWarranty(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setPatchingWarranty(false);
        return;
      }

      data.customer = customerSelected;
      data.owner = userSelected;
      data.receptionDate = etadate !== null ? etadate : null;

      await axios.patch(`${API_WARRANTY}/${currentWarranty.uuid}`,data).then(response => {
        if(response.data){
          getWarranties();
          setPatchingWarranty(false);
          toast.success("¡Garantía actualizada con Éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingWarranty(false);
      }).catch(e => {
        setPatchingWarranty(false);
        toast.error("No se pudo actualizar la Garantía: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataWarrantyList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
      && user.role !== "COTIZADOR"
    ){
      navigate('/inicio');
    }
    getWarranties();
    getCustomersList();
    getUsersList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Compras" title="Garantías"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Nueva</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  paginationRowsPerPageOptions={[50,100,200,500]}
                  noDataComponent={<b style={{padding:10}}>No se encontraron ordenes de compra</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'55%'}}>
        <ModalHeader toggle={toggle}>{isEditingWarranty ? 'Editar Orden de Compra' : 'Crear Nuevo Orden de Compra' }
        {isEditingWarranty ?
        <>
          <div style={{display:'flex',justifyContent:'left'}}><a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/purchase?uuid=${currentWarranty.uuid}`} target="_blank">Ver Documento</a></div>
          </>
        : null}
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingWarranty ? patchWarranty : saveWarranty)} style={{padding:20}}>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Folio
                  </Label>
                  <input className="form-control" type="text" placeholder="Folio" name="folio" {...register("folio", {required: true})} />
                  <span style={{color:'red'}}>{errors.folio && "- Folio Requerido"}</span>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Factura CIESA
                  </Label>
                  <input className="form-control" type="text" placeholder="Factura CIESA" name="ciesaInvoice" {...register("ciesaInvoice", {required: true})} />
                  <span style={{color:'red'}}>{errors.ciesaInvoice && "- Factura CIESA"}</span>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Cliente
                  </Label>
                  <Typeahead
                    id="customers"
                    clearButton
                    defaultSelected={customerSelected}
                    labelKey="name"
                    options={dataCustomers}
                    placeholder="Buscar Cliente..."
                    onChange={(e)=>{setCustomerSelected(e)}}
                  />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Orden de Compra Cliente
                  </Label>
                  <input className="form-control" type="text" placeholder="Orden de Compra Cliente" name="customerPurchaseOrder" {...register("customerPurchaseOrder", {required: true})} />
                  <span style={{color:'red'}}>{errors.customerPurchaseOrder && "- Orden de Compra de Cliente Requerido"}</span>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Item o Partida
                  </Label>
                  <input className="form-control" type="text" placeholder="Item o Partida" name="item" {...register("item", {required: true})} />
                  <span style={{color:'red'}}>{errors.item && "- Item o Partida"}</span>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Descripción ITEM
                  </Label>
                  <input className="form-control" type="text" placeholder="Descripción ITEM" name="description" {...register("description")} />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Razón de Devolución
                  </Label>
                  <input className="form-control" type="text" placeholder="Razón de Devolución" name="reason" {...register("reason", {required: true})} />
                  <span style={{color:'red'}}>{errors.reason && "- Razón de Devolución"}</span>
                </Col>
              </Row>
              {isEditingWarranty ?
              <>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Estado
                    </Label>
                    <select defaultValue={statusWarranty} name="status" {...register("status", { required: true })} className="form-control" onChange={(e)=>setstatusWarranty(e.target.value)}>
                      <option value="PENDING">POR APROBAR</option>
                      <option value="APPROVED">APROBADA</option>
                      <option value="TRANSIT">EN TRANSITO</option>
                      <option value="FINISHED">RECIBIDA</option>
                      <option value="CANCELLED">CANCELADA</option>
                    </select>
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Folio entrada almacen
                    </Label>
                    <input className="form-control" type="text" placeholder="Folio entrada almacen" name="folioWarehouse" {...register("folioWarehouse")} />
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Fecha de Recepción
                    </Label>
                    <DatePicker className="form-control digits"
                        selected={etadate}
                        onChange={handleChangeEtaDate}
                        selectsStart
                        locale={es}
                        dateFormat={'dd/MM/yyyy h:mm aa'}
                        showTimeSelect
                      />
                  </Col>
                </Row>
              </> : null }
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold">
                    Responsable
                  </Label>
                  <Typeahead
                    id="users"
                    clearButton
                    defaultSelected={userSelected}
                    labelKey="name"
                    options={dataUsers}
                    placeholder="Buscar Usuarios..."
                    onChange={(e)=>{setUserSelected(e)}}
                  />
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="notes">
                    Notas Internas
                  </Label>
                  <textarea className="form-control" id="notes" placeholder="Notas" name="notes" {...register("notes")} ></textarea>
                </Col>
              </Row>
              <Row className="g-3 dflex mb-3">
                <Col md="12">
                  <Label className="form-label font-weight-bold" for="notes">
                    Notas para el Cliente
                  </Label>
                  <textarea className="form-control" id="publicNotes" placeholder="Notas" name="publicNotes" {...register("publicNotes")} ></textarea>
                </Col>
              </Row>

              <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                {
                  isEditingWarranty ? 
                    <Button disabled={patchingPurchase} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                  : 
                    <Button disabled={savingWarranty} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                }
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default Warranty;