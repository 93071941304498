import React, { } from 'react';
import { API_FILES } from '../../redux/apiRoutes';

export const HydroCapture = (props) => {

    const upload = async (files) => {
        const formData = new FormData();
        formData.append('file', files[0]); // Adjuntar el archivo al FormData
    
        try {
          const response = await fetch(`${API_FILES}/upload`, {
            method: 'POST',
            body: formData,
          });
    
          if (!response.ok) {
            throw new Error('Error al subir el archivo.');
          }
    
          const data = await response.json();
          const url = data.filePath; // Ruta del archivo en el servidor
          props.attach([url]); // Pasar la URL o ruta al componente padre
          console.log('Archivo subido exitosamente:', url);
        } catch (error) {
          console.error('Error al subir el archivo:', error);
        }
      };
    
      return (
        <div className="webcam-container">
          <input
            type="file"
            accept='image/jpg, image/jpeg, image/png, mp4'
            onChange={(event) => upload(event.target.files)}
          />
        </div>
      );
};

export const DocumentCapture = (props) => {
    const upload = async (files) => {
      const formData = new FormData();
      formData.append('file', files[0]); // Adjuntar el archivo al FormData
  
      try {
        const response = await fetch(`${API_FILES}/upload`, {
          method: 'POST',
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('Error al subir el archivo.');
        }
  
        const data = await response.json();
        const url = data.filePath; // Ruta del archivo en el servidor
        props.attach([url]); // Pasar la URL o ruta al componente padre
        console.log('Archivo subido exitosamente:', url);
      } catch (error) {
        console.error('Error al subir el archivo:', error);
      }
    };
  
    return (
      <div className="webcam-container">
        <input
          type="file"
          onChange={(event) => upload(event.target.files)}
        />
      </div>
    );
  };