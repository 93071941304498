import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Nav, Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane, } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from '../../../axiosConfig.js';
import { API_SERVICE, API_CUSTOMER, API_USER, API_COMPANY, API_URL, API_FILES } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";
import { HydroCapture } from "../../common/hydro";
import { SerachPedimentsComponent } from "../../common/searchPedimentsComponent.js";

const ValvesFix = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState("1");
  const [activeCamera, setactiveCamera] = useState(false);

  const handleviewCamera = () => {setactiveCamera(!activeCamera)}

  const [pictures, setPictures] = useState([]);
  const [attaching, setAttaching] = useState(false);
  const attach = async (pic) => {
    setAttaching(true);

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,{newPic:pic[0]}).then(response => {
      if(response.data){
        getEventList();
        setPictures(response.data.pictures)
        setAttaching(false);
        toast.success("¡Foto agregada con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setAttaching(false);
    }).catch(e => {
      setAttaching(false);
      toast.error("No se pudo agregar la foto al ensamble: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [hydros, setHydros] = useState([]);
  const [attachingHydro, setAttachingHydro] = useState(false);
  const attachHydro = async (hydro) => {
    setAttachingHydro(true);

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,{newHydro:hydro[0]}).then(response => {
      if(response.data){
        getEventList();
        setHydros(response.data.hydro)
        setAttachingHydro(false);
        toast.success("¡Reporte Hydro agregado con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setAttachingHydro(false);
    }).catch(e => {
      setAttachingHydro(false);
      toast.error("No se pudo agregar el reporte Hydro al ensamble: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const handleDeleteActionPicture = (file) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la foto del ensamble.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          // Extraer el nombre del archivo desde la URL
          const filePath = file.split('uploads/')[1];

          // Enviar solicitud al backend para eliminar el archivo
          await axios.delete(`${API_FILES}/delete`, {
            params: { filePath }, // Pasar el nombre del archivo como query param
          });
  
          // Actualizar la lista de imágenes en el frontend
          const newPictures = pictures.filter((item) => item !== file);
          await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`, { pictures: newPictures });
          
          setPictures(newPictures);
          getEventList();
  
          toast.success("Imagen eliminada correctamente.");
        } catch (error) {
          toast.error("Error al eliminar la imagen: " + error.response?.data?.message || error.message);
        }
      }
    });
  }

  const handleDeleteActionHydro = (file) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la foto del reporte Hydro.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          // Extraer el nombre del archivo desde la URL
          const filePath = file.split('uploads/')[1];
  
          // Enviar solicitud al backend para eliminar el archivo
          await axios.delete(`${API_FILES}/delete`, {
            params: { filePath }, // Pasar el nombre del archivo como query param
          });
  
          const newHydros = hydros.filter((item) => item !== file);
          await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`, { hydro: newHydros });
          
          setHydros(newHydros);
          getEventList();
  
          toast.success("Reporte eliminado correctamente.");
        } catch (error) {
          toast.error("Error al eliminar el reporte: " + error.response?.data?.message || error.message);
        }
      }
    });
  }
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinEvent(false); clearModal(); setPediments(clearPediments);};

  const [isOpen, setIsOpen] = useState(1);
  const toggleAccordion = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const clearModal = () => {
    setactiveCamera(false);
    setCustomerSelected([]);
    setPictures([]);
    setHydros([]);
    setCurrentEvent({});
    setUserSelected([]);
    setstartDate(new Date());
    setendDate(new Date());
    setEditDocument(true);
    setPrimarycolorLineTab('1')
    setContent('')
  }

  const [company, setcompany] = useState({});
  const getCompany = async () => {
    await axios.get(`${API_COMPANY}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        response.data.docs.map(company=>{
          setcompany(company);
        });
      }
    }).catch(e => {
      toast.error("No se pudo obtener la información de la empresa: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [userSelected,setUserSelected] = useState([]);
  const [dataUsers,setdataUsers] = useState([]);
  const getUsersList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&role=CAPTURISTA,SUPERVISOR,GERENTE`).then(response => {
      if(response.data.docs.length>0){
        const users = [];
        response.data.docs.map(user=>{
          const addUser = {
            uuid: user.uuid,
            name: user.name,
            eventAmount: user.eventAmount,
          }
          users.push(addUser);
        });
        setdataUsers(users);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [customerSelected,setCustomerSelected] = useState([]);
  const [dataCustomers,setdataCustomers] = useState([]);
  const getCustomersList = async () => {
    await axios.get(`${API_CUSTOMER}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const customers = [];
        response.data.docs.map(customer=>{
          const addCustomer = {
            uuid: customer.uuid,
            name: customer.name,
            price: customer.price,
            products: customer.products,
          }
          customers.push(addCustomer);
        });
        setdataCustomers(customers);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Cirugias: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataEventList, setDataEventList] = useState([]);
  const [isGetEventList, setIsGetEventList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getEventList = async () => {
    setIsGetEventList(true);

    let queryRule = '';
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "GERENTE"){
      setEditPermissions(true);
      queryRule = `type=FIXED`;
    }

    if(user.role === "CAPTURISTA"){
      setEditPermissions(false);
      queryRule = `type=FIXED`;
    }
    
    await axios.get(`${API_SERVICE}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataEventList(response.data.docs);
      }else{
        setDataEventList([]);
      }
      setIsGetEventList(false);
    }).catch(e => {
      setIsGetEventList(false);
      toast.error("No se pudo obtener el listado de Ensambles: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const columns = [
    {
      name: "No. Serie",
      selector: (row) => <b>{row.autoSerial}</b>,
      sortable: true,
      center: true,
      width: '160px'
    },
    {
      name: "Orde de Compra",
      selector: (row) => <b>{row.eventId}</b>,
      sortable: true,
      center: true,
      width: '160px'
    },
    {
      name: "Equipo",
      selector: (row) => <b>{row.device}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Modelo",
      selector: (row) => row.modelo,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Tamaño",
      selector: (row) => row.size,
      sortable: true,
      center: true,
      width: '160px'
    },
    {
      name: "Cliente",
      selector: (row) => row.customer !== null && row.customer !== undefined && row.customer.length > 0 ? <b>{row.customer[0].name}</b> : '-',
      sortable: true,
      center: true,
    },
    {
      name: "Fecha Inicio",
      selector: (row) => row.startTime ? new Date(row.startTime).getDate() + '-' + (new Date(row.startTime).getMonth() + 1) + '-' + new Date(row.startTime).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Fecha Fin",
      selector: (row) => row.endTime ? new Date(row.endTime).getDate() + '-' + (new Date(row.endTime).getMonth() + 1) + '-' + new Date(row.endTime).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}>{row.edit ? <i className="fa fa-pencil"></i> : <i className="fa fa-pencil"></i>}</Button>
                          {editPermissions?
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button> : null}
                        </div>,
      width: '160px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [startdate, setstartDate] = useState(null);
  const handleChangeStartDate = (date) => {
    setstartDate(date);
  };

  const [enddate, setendDate] = useState(null);
  const handleChangeEndDate = (date) => {
    setendDate(date);
  };

  const [savingEvent, setSaveEvent] = useState(false);
  const saveEvent = async (data) => {
    setSaveEvent(true);
    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setSaveEvent(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setSaveEvent(false);
        return;
      }

      data.uuid = uuidv4();
      data.customer = customerSelected;
      data.startTime = startdate !== null ? startdate : null;
      data.endTime = enddate !== null ? enddate : null;
      data.customer = customerSelected;
      data.owner = userSelected;
      data.type = 'FIXED';

      await axios.post(`${API_SERVICE}`,data).then(response => {
        if(response.data){
          getEventList();
          setEditinEvent(true);
          toast.success("¡Ensamble creado con éxito! Continua Editando", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveEvent(false);
        setCurrentEvent(response.data);
      }).catch(e => {
        setSaveEvent(false);
        toast.error("No se pudo crear el ensamble: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentEvent, setCurrentEvent] = useState({});
  const [editDocument, setEditDocument] = useState(true);
  const handleEditAction = (event) => {
    toggle();
    setEditinEvent(true);
    setCurrentEvent(event);
    
    if(event.customer!==undefined && event.customer !== null && event.customer.length>0){
      dataCustomers.map((customer)=>{
        if(customer.uuid===event.customer[0].uuid){
          setCustomerSelected([customer]);
        }
      });
    }

    if(event.owner!==undefined && event.owner !== null){
      dataUsers.map((user)=>{
        if(user.uuid===event.owner[0].uuid){
          setUserSelected([user]);
        }
      });
    }

    populate(event);
    
    setTimeout(()=>{
      setValue('eventId', event.eventId);
      setValue('place', event.place);
      setValue('contact', event.contact);
      setValue('line', event.line);
      setValue('device', event.device);
      setValue('modelo', event.modelo);
      setValue('size', event.size);
      setValue('autoSerial', event.autoSerial);
      setstartDate(event.startTime !== null && event.startTime !== undefined ? new Date(event.startTime) : null);
      setendDate(event.endTime !== null && event.endTime !== undefined ? new Date(event.endTime) : null);
    },500)
  }

  const populate = (event) => {
    setPictures(event.pictures !== null && event.pictures !== undefined ? event.pictures : []);
    setHydros(event.hydro !== null && event.hydro !== undefined ? event.hydro : []);
    setPediments(event.pediment !== null && event.pediment !== undefined ? event.pediment: clearPediments);
  }

  const [isEditingEvent, setEditinEvent] = useState(false);
  const [patchingEvent, setPatchingEvent] = useState(false);
  const patchEvent = async (data) => {
    setPatchingEvent(true);

    if (data !== "") {
      if(customerSelected.length<=0){
        toast.error("Asigna un cliente", {position: 'bottom-right', style:{color:'white'}});
        setPatchingEvent(false);
        return;
      }

      if(userSelected.length<=0){
        toast.error("Asigna un responsable", {position: 'bottom-right', style:{color:'white'}});
        setPatchingEvent(false);
        return;
      }

      data.startTime = startdate !== null ? startdate : null;
      data.endTime = enddate !== null ? enddate : null;
      data.customer = customerSelected;
      data.owner = userSelected;
      data.pediment = pediment;

      await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,data).then(response => {
        if(response.data){
          getEventList();
          setPatchingEvent(false);
          toast.success("¡Ensamble actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingEvent(false);
      }).catch(e => {
        setPatchingEvent(false);
        toast.error("No se pudo actualizar el ensamble: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const patchPediment = async () => {
    setPatchingEvent(true);

    const data = {
      pediment: pediment,
    }

    await axios.patch(`${API_SERVICE}/${currentEvent.uuid}`,data).then(response => {
      if(response.data){
        setPatchingEvent(false);
        getEventList();
        toast.success("Información de pedimento actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
      }
      setPatchingEvent(false);
    }).catch(e => {
      setPatchingEvent(false);
      toast.error("No se pudo actualizar la información pedimento del embarque: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará el ensamble para: ${data.customer[0].name} las imagenes e información se borrarán, la acción es irreversible.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        if(data.pictures !== null && data.pictures !== undefined){
          await Promise.all(data.pictures.map(async (img)=>{
            const filePath = img.split('uploads/')[1];

            await axios.delete(`${API_FILES}/delete`, {
              params: { filePath },
            });
          }))
        }

        if(data.hydro !== null && data.hydro !== undefined){
          await Promise.all(data.hydro.map(async (img)=>{
            const filePath = img.split('uploads/')[1];

            await axios.delete(`${API_FILES}/delete`, {
              params: { filePath },
            });
          }))
        }

        await axios.delete(`${API_SERVICE}/${data.uuid}`).then(response => {
          getEventList();
          SweetAlert.fire("¡Listo!", "ensamble eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el ensamble: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataEventList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [content, setContent] = useState("");
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
  };

  const clearPediments = [
    {
      pid:1,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:2,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:3,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:4,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:5,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:6,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:7,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:8,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:9,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:10,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:11,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:12,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:13,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:14,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:15,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:16,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:17,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:18,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:19,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:20,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:21,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:22,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:23,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:24,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:25,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:26,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:27,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:28,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:29,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:30,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:31,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:32,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:33,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:34,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:35,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:36,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:37,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:38,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:39,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
    {
      pid:40,
      number: "",
      description: "",
      qty: "",
      pediment: "",
    },
  ];

  const [pediment, setPediments] = useState(clearPediments);

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
      && user.role !== "CAPTURISTA"
      && user.role !== "ALMACEN"
    ){
      navigate('/inicio');
    }
    getCustomersList();
    getEventList();
    getUsersList();
    getCompany();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Producción" title="Reparación de Válvulas"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Ensamble</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  noDataComponent={<b style={{padding:10}}>No se encontraron nuevas válvulas</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'55%'}}>
      <ModalHeader toggle={toggle}>{isEditingEvent ? `Editar Ensamble "${currentEvent.eventId}"` : `Crear Ensamble` }
        {isEditingEvent ?
          <>
            <div style={{display:'flex',justifyContent:'left', marginRight:10}}>
              <a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/report?uuid=${currentEvent.uuid}&type=FIXED`} target="_blank">Ver Orden</a>
            </div> 
            <div style={{display:'flex',justifyContent:'left'}}>
              <a style={{color:'blue'}} href={`${process.env.REACT_APP_PUBLIC_URL}/pediment?uuid=${currentEvent.uuid}&type=FIXED`} target="_blank">Ver Pedimentos</a>
            </div> 
          </>
        : null }
        <button className="btn-close invisible" type="button">
          <span aria-hidden="true" className="visible" onClick={toggle}></span>
        </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            {
              editDocument ?
                <>
                  {isEditingEvent ?
                  <Nav className="nav-primary" tabs>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "1" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("1")}
                      >
                        <i className="icofont icofont-list"></i>Detalles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "4" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("4")}
                      >
                        <i className="icofont icofont-image"></i>Galería
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "5" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("5")}
                      >
                        <i className="icofont icofont-dashboard"></i>Reporte
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{cursor:'pointer'}}
                        className={PrimarycolorLineTab === "6" ? "active" : ""}
                        onClick={() => setPrimarycolorLineTab("6")}
                      >
                        <i className="icofont icofont-list"></i>Pedimento
                      </NavLink>
                    </NavItem>
                  </Nav> : null}
                  <TabContent activeTab={PrimarycolorLineTab}>
                    <TabPane className="fade show" tabId="1">
                      <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingEvent ? patchEvent : saveEvent)} style={{padding:20}}>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Cliente
                            </Label>
                            <Typeahead
                              id="customers"
                              clearButton
                              defaultSelected={customerSelected}
                              labelKey="name"
                              options={dataCustomers}
                              placeholder="Buscar Cliente..."
                              onChange={(e)=>{setCustomerSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validserviceId">
                              Orden de Compra
                            </Label>
                            <input className="form-control" id="validserviceId" type="text" placeholder="Orden de Compra" name="eventId" {...register("eventId", { required: true })} />
                            <span>{errors.eventId && "Orden de Compra Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validContact">
                              Contacto
                            </Label>
                            <input className="form-control" id="validContact" type="text" placeholder="Contacto para el Cliente" name="contact" {...register("contact", { required: true })} />
                            <span>{errors.contact && "Contacto del ensamble Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validLine">
                              Línea
                            </Label>
                            <input className="form-control" value="SERVICES" id="validLine" type="text" placeholder="Línea del ensamble" name="line" {...register("line", { required: true })} />
                            <span>{errors.line && "Línea del ensamble Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Fecha y hora Inicio
                            </Label>
                            <DatePicker className="form-control digits"
                                selected={startdate}
                                onChange={handleChangeStartDate}
                                selectsStart
                                locale={es}
                                dateFormat={'dd/MM/yyyy h:mm aa'}
                                showTimeSelect
                              />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Fecha y hora Fin
                            </Label>
                            <DatePicker className="form-control digits"
                                selected={enddate}
                                onChange={handleChangeEndDate}
                                selectsStart
                                locale={es}
                                dateFormat={'dd/MM/yyyy h:mm aa'}
                                showTimeSelect
                              />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold">
                              Realiza
                            </Label>
                            <Typeahead
                              id="users"
                              clearButton
                              defaultSelected={userSelected}
                              labelKey="name"
                              options={dataUsers}
                              placeholder="Buscar Usuarios..."
                              onChange={(e)=>{setUserSelected(e)}}
                            />
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validdevice">
                              Equipo
                            </Label>
                            <input className="form-control" id="validdevice" type="text" placeholder="Nombre del Equipo" name="device" {...register("device", { required: true })} />
                            <span>{errors.device && "Nombre del Equipo Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validmodel">
                              Modelo
                            </Label>
                            <input className="form-control" id="validmodel" type="text" placeholder="Modelo del Equipo" name="modelo" {...register("modelo", { required: true })} />
                            <span>{errors.modelo && "Modelo del Equipo Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validsize">
                              Tamaño
                            </Label>
                            <input className="form-control" id="validsize" type="text" placeholder="Tamaño" name="size" {...register("size", { required: true })} />
                            <span>{errors.size && "Tamaño Requerido"}</span>
                          </Col>
                        </Row>
                        <Row className="g-3 dflex mb-3">
                          <Col md="12">
                            <Label className="form-label font-weight-bold" for="validserial">
                              Número de Serie
                            </Label>
                            {
                              isEditingEvent ? 
                                <input className="form-control" id="validserial" type="text" placeholder="Número de Serie" name="autoSerial" {...register("autoSerial", {required:true})} />
                            : 
                                <input className="form-control" id="validserial" type="text" placeholder="Número de Serie" name="autoSerial" {...register("autoSerial", {required:true})} />
                            }
                            <span>{errors.autoSerial && "Número de Serie Requerido"}</span>
                          </Col>
                        </Row>
                        <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                        {
                          isEditingEvent ? 
                            <Button disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                          : 
                            <Button disabled={savingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                        }
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{display:'flex',justifyContent:'center', marginBottom:30}} >
                        {/* {activeCamera?
                          <WebcamCapture attach={attach} callBack={handleviewCamera}></WebcamCapture>
                        : <button onClick={()=>setactiveCamera(true)}>+ Agregar Foto</button> } */}
                        <HydroCapture attach={attach}/>
                      </div>
                      <Card className="p-3
                      ">
                        <div className="row">
                          {
                            pictures.map((img,i)=>{
                              return(
                                <div key={i} className="flex justify-start mr-5 col-3">
                                  <img className="mb-5 rounded" src={API_URL+img} alt={"image-" + i} style={{maxWidth:220, maxHeight:220}} />
                                  <div className="text-center" style={{background:'red', color:'white', width:30, height:30, paddingRight:10, paddingLeft:10, paddingTop:3, marginLeft:-15, borderRadius:50, cursor:'pointer', top:0, right: 10, position:'absolute'}} onClick={()=>handleDeleteActionPicture(img)}><b>x</b></div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </Card>
                    </TabPane>
                    <TabPane tabId="5">
                      <h4>Cargar archivos Hydrostatic Test Report:</h4>
                      <HydroCapture attach={attachHydro}/>
                      <Card className="p-3 mt-3">
                        <div className="row">
                          {
                            hydros.map((img,i)=>{
                              return(
                                <div key={i} className="flex justify-start mr-5 col-3">
                                  <img className="mb-5 rounded" src={API_URL+img} alt={"image-" + i} style={{width:'100%'}} />
                                  <div className="text-center" style={{background:'red', color:'white', width:30, height:30, paddingRight:10, paddingLeft:10, paddingTop:3, marginLeft:-15, borderRadius:50, cursor:'pointer', top:0, right: 10, position:'absolute'}} onClick={()=>handleDeleteActionHydro(img)}><b>x</b></div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </Card>
                    </TabPane>
                    <TabPane tabId="6">
                      <Card className="p-3 mt-3">
                        <SerachPedimentsComponent pediment={pediment} setPediments={setPediments} />
                        <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                          {
                            isEditingEvent ? 
                              <Button onClick={patchPediment} disabled={patchingEvent} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                            : null
                          }
                        </Row>
                      </Card>
                    </TabPane>
                  </TabContent>
                </>
              :
              null
            }
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ValvesFix;
