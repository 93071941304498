export class ConfigDB {
  static data = {
    settings: {
      layout_type: "ltr",
      sidebar: {
        type: "horizontal_sidebar",
      },
    },
    color: {
      layout_version: "light",
      color: "color-1",
      primary_color: "#693C97",
      secondary_color: "#2C3691",
      third_color: "#FFC103",
      four_color: "	#c1461e",
      five_color: "#a4d25b",
      mix_layout: "color-only",
    },

    router_animation: "fade",
  };
}

export default ConfigDB;
