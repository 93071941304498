import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AppLayout from "../App";

import configDB from "../data/customizer/config";
import PrivateRoute from "./private-route";

import Signin from "../auth/signin";

import { routes } from "./layouts-routes";

import { useSelector } from "react-redux";
import Report from "../public/report";

const MainRoutes = () => {
  const user = useSelector((content) => content.User);

  const [currentUser, setCurrentUser] = useState(false);
  useEffect(() => {
    const abortController = new AbortController();
    const color = localStorage.getItem("color");
    const layout = localStorage.getItem("layout_version") || configDB.data.color.layout_version;
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<PrivateRoute token={user.uuid} />}>
          {routes.map(({ path, Component }, i) => (
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          ))}
          <Route path="" element={<Navigate to={`${process.env.PUBLIC_URL}/inicio`} />} />
        </Route>

        <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
        <Route exact path={`${process.env.PUBLIC_URL}/report`} element={<Report />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
