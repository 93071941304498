import React from "react";
import { Container, Row, Col } from "reactstrap";
const Footer = (props) => {
  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col md="6" className="footer-copyright">
            <p className="mb-0">© {new Date().getFullYear()} CIESA</p>
          </Col>
          <Col md="6">
            {/* <p className="pull-right mb-0">
              <a style={{color:'blue'}} href="https://codersgdl.com/apps">Desarrollado por Coders GDL</a>
            </p> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
