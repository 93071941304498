import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container, Col, Row, Card, CardHeader, CardBody } from "reactstrap";

import { useSelector } from "react-redux";
import { useEffect } from "react";

import Apexcharts from "react-apexcharts";
import Apexchart from "react-apexcharts";
import { apexchartswidgets6 } from "./charts/apexchartsData";

import {
  apexPieChart,
} from "./charts/apexchartsDataB";
import { Clock, Database, MessageCircle, ShoppingBag } from "react-feather";
import { getFormatMoney } from "../../../utils/functions";
import moment from "moment";

const Home = () => {
  const user = useSelector((content) => content.User);

  const [getCutReport, setgetCutReport] = useState(false);
  const [dataReport,setDataReport] = useState(null);
  let [chartOptions, setChartOptions] = useState(apexchartswidgets6.options);
  const getDataReport = async (start,end,filter) => {
    setgetCutReport(true);

    // await axios.get(`${API_REPORT}/filter?start_date=${start}&end_date=${end}&filter=${filter}`).then(response => {
    //   if(response.data){
    //     console.log(response.data);
    //     apexchartswidgets6.options.labels = response.data.balanceLabels;
    //     setChartOptions(apexchartswidgets6.options);
    //     setDataReport(response.data);
    //   }
    //   setgetCutReport(false);
    // }).catch(e => {
    //   setgetCutReport(false);
    //   toast.error("No se pudo obtener el Reporte del Corte de Caja: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    // });
  }

  const [startDate, setstartDate] = useState(new Date());
  const setFilter = (value) => {
    // if(value==='WEEK'){
    //   const start = moment(new Date()).subtract(7,'days');
    //   const end = moment(new Date());
    //   getDataReport(start, end, value);
    // }
    // if(value==='MONTH'){
    //   const start = moment(new Date()).subtract(31,'days');
    //   const end = moment(new Date());
    //   getDataReport(start, end, value);
    // }
    // if(value==='QUARTER'){
    //   const start = moment(new Date()).subtract(90,'days');
    //   const end = moment(new Date());
    //   getDataReport(start, end, value);
    // }
    // if(value==='YEAR'){
    //   const start = moment(new Date()).subtract(365,'days');
    //   const end = moment(new Date());
    //   getDataReport(start, end, value);
    // }
  };

  useEffect(() => {
    // const start = moment(new Date()).subtract(7,'days');
    // const end = moment(new Date());
    // getDataReport(start, end, 'WEEK');
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Inicio" title="Bienvenid@" subtitle={user.name} />
      {dataReport !== null ?
        <Container fluid={true}>
          <Row>
            { user.role === 'ADMIN' || user.role === 'SISTEMAS' ?
            <Col xl="4" sm="4 box-col-4 xl-25">
              <Card className="gradient-primary o-hidden">
                <CardBody className="b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Database />
                    </div>
                    <div className="media-body">
                      <span className="m-0 text-white">Saldo estimado en Caja</span>
                      <h4 className="mb-0 counter">{getFormatMoney(dataReport.estimatedBalance)}</h4>
                      <Database className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> : null}
            <Col xl="4" sm="4 box-col-4 xl-25">
              <Card className="gradient-secondary o-hidden">
                <CardBody className="b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <ShoppingBag />
                    </div>
                    <div className="media-body">
                      <span className="m-0">Por Cobrar</span>
                      <h4 className="mb-0 counter">{getFormatMoney(dataReport.toCollect)}</h4>
                      <ShoppingBag className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4" sm="4 box-col-4 xl-25">
              <Card className="gradient-warning o-hidden">
                <CardBody className="b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center">
                      <Clock className="text-white i" />
                    </div>
                    <div className="media-body">
                      <span className="m-0 text-white">Cirugias Programadas</span>
                      <h4 className="mb-0 counter text-white">{dataReport.programs}</h4>
                      <Clock className="icon-bg" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12 xl-100 box-col-12 d-flex" style={{marginTop:40}}>
              <h4>Mostrando datos:</h4> &nbsp;
              <select onChange={(e)=>setFilter(e.target.value)} className="form-control" style={{marginTop:-15, marginLeft:20, width:300, padding:10, background: '#dfdfdf'}}>
                <option value='WEEK'>Semana en Curso</option>
                <option value='MONTH'>Último Mes</option>
                <option value='QUARTER'>Último Trimestre</option>
                <option value='YEAR'>Último Año</option>
              </select>
            </Col>
            { !getCutReport ? 
            <Col xl="12 xl-100 box-col-12" style={{marginTop:40}}>
              { user.role === 'ADMIN' || user.role === 'SISTEMAS' ?
              <Card className="crypto-revenue">
                <CardHeader className="pb-0 d-flex">
                  <h5>
                    Balance General
                  </h5>
                  <ul className="creative-dots">
                    <li className="bg-primary big-dot"></li>
                    <li className="bg-secondary semi-big-dot"></li>
                    <li className="bg-warning medium-dot"></li>
                    <li className="bg-info semi-medium-dot"></li>
                    <li className="bg-secondary semi-small-dot"></li>
                    <li className="bg-primary small-dot"></li>
                  </ul>
                  <div className="header-right pull-right text-end">
                    {/* <h5 className="mb-2">Semana</h5>
                    <h6 className="f-w-700 mb-0">Total 81,67,536 $</h6> */}
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <Apexcharts options={chartOptions} series={dataReport.balance} height="300" type="area" />
                </CardBody>
              </Card> :null}
            </Col> :null}
          </Row>
          { !getCutReport ? 
            <>
              <Row>
                <Col xl="6 xl-50 box-col-6">
                  <Card className="crypto-revenue">
                    <CardHeader className="pb-0 d-flex">
                      <h5>
                        Créditos y Cobranza
                      </h5>
                      <ul className="creative-dots">
                        <li className="bg-primary big-dot"></li>
                        <li className="bg-secondary semi-big-dot"></li>
                        <li className="bg-warning medium-dot"></li>
                        <li className="bg-info semi-medium-dot"></li>
                        <li className="bg-secondary semi-small-dot"></li>
                        <li className="bg-primary small-dot"></li>
                      </ul>
                      <div className="header-right pull-right text-end">
                        {/* <h5 className="mb-2">Semana</h5>
                        <h6 className="f-w-700 mb-0">Total 81,67,536 $</h6> */}
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <Apexcharts options={apexchartswidgets6.options} series={dataReport.payments} height="300" type="area" />
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6 xl-50 box-col-6">
                  <Card className="crypto-revenue">
                    <CardHeader className="pb-0 d-flex">
                      <h5>
                        Descuentos
                      </h5>
                      <ul className="creative-dots">
                        <li className="bg-primary big-dot"></li>
                        <li className="bg-secondary semi-big-dot"></li>
                        <li className="bg-warning medium-dot"></li>
                        <li className="bg-info semi-medium-dot"></li>
                        <li className="bg-secondary semi-small-dot"></li>
                        <li className="bg-primary small-dot"></li>
                      </ul>
                      <div className="header-right pull-right text-end">
                        {/* <h5 className="mb-2">80 / 100</h5>
                        <h6 className="f-w-700 mb-0">Total 81,67,536 $</h6> */}
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <Apexcharts options={apexchartswidgets6.options} series={dataReport.countDisccounts} height="300" type="area" />
                    </CardBody>
                  </Card>
                </Col>
              </Row>        
              <Row style={{marginBottom:70}}>
                <Col xl="6 xl-50 box-col-6">
                  <Card className="crypto-revenue">
                    <CardHeader className="pb-0 d-flex">
                      <h5>
                        Servicios realizados
                      </h5>
                      <ul className="creative-dots">
                        <li className="bg-primary big-dot"></li>
                        <li className="bg-secondary semi-big-dot"></li>
                        <li className="bg-warning medium-dot"></li>
                        <li className="bg-info semi-medium-dot"></li>
                        <li className="bg-secondary semi-small-dot"></li>
                        <li className="bg-primary small-dot"></li>
                      </ul>
                      <div className="header-right pull-right text-end">
                        {/* <h5 className="mb-2">80 / 100</h5>
                        <h6 className="f-w-700 mb-0">Total 81,67,536 $</h6> */}
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                      <Apexcharts options={apexchartswidgets6.options} series={dataReport.countServices} height="300" type="area" />
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col sm="6" xl="6  xl-50">
                  <Card>
                    <CardHeader>
                      <h5>Servicios por hospital </h5>
                    </CardHeader>
                    <CardBody className="apex-chart">
                      <div id="piechart">
                        <Apexchart
                          options={apexPieChart.options}
                          series={apexPieChart.series}
                          type="pie"
                          width={350}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
                {/* <Col sm="6" xl="6  xl-50">
                  <Card>
                    <CardHeader>
                      <h5>Pagos y Créditos </h5>
                    </CardHeader>
                    <CardBody className="apex-chart">
                      <div id="piechart">
                        <Apexchart
                          options={apexPieChart.options}
                          series={apexPieChart.series}
                          type="pie"
                          width={350}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
                {/* <Col sm="6" xl="6  xl-50">
                  <Card>
                    <CardHeader>
                      <h5>Cirugias realizadas </h5>
                    </CardHeader>
                    <CardBody className="apex-chart">
                      <div id="piechart">
                        <Apexchart
                          options={apexPieChart.options}
                          series={apexPieChart.series}
                          type="pie"
                          width={350}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </>
          : null }
        </Container>
      : null}
    </Fragment>
  );
};

export default Home;
