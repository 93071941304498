import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { API_QUOTE } from "../redux/apiRoutes";
import axios from '../axiosConfig.js';

import { jsPDF } from "jspdf";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { File, Printer } from "react-feather";
import { estado } from "../utils/functions";

const Quote = () => {
  const [currentQuote, setcurrentQuote] = useState(null);

  const [getParamsUrl, setgetparamsUrl] = useState(false);
  const url = new URL((typeof document === "undefined") ? 'http://localhost' : document.URL);
  const navigate = useNavigate();

  const setURLParams = () => {
    if(url.searchParams.get("uuid")){
      getQuoteData(url.searchParams.get("uuid"));
    }else{
      navigate('/inicio');
    }
  }

  const getQuoteData = async (uuid,type) => {
    await axios.get(`${API_QUOTE}?uuid=${uuid}`).then(response => {
      if(response.data.docs.length>0){
        setcurrentQuote(response.data.docs[0]);
      }else{
        navigate('/inicio');
      }
    }).catch(e => {
      console.log(e);
      //navigate('/inicio');
    });
  }

  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    if(!getParamsUrl){
        setURLParams();
        setgetparamsUrl(true);
    }
  }, [getParamsUrl,setURLParams,setgetparamsUrl]);

  return (
    <div className="page-wrapper" style={{background:'#444'}}>
      <Container fluid={true} className="p-0">
        <div className="m-0">
          <Row>
            <Col md="12" style={{minWidth:700}}>
            {currentQuote !== null ? 
              <CardBody>
                <div className="mt-4 mb-4 cont text-center b-light">
                  <Row className="mb-3">
                    <Col md="3" xs="3">
                      <img src={currentQuote.logo !== "" && currentQuote.logo !== undefined && currentQuote.logo !== null ? currentQuote.logo : "https://ciesa.site/logo192.png"} alt="Ciesa" width={150} />
                    </Col>
                    <Col md="9" xs="9">
                      <h4 className="font-weight-bold">COTIZACION No. {currentQuote.quoteId}</h4>
                      <table border={0} style={{width:'100%'}}>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Fecha:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.createdAt.split('T')[0]}</td>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Cliente:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.customer[0].name}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Vigencia:</td>
                          <td style={{border:'1px solid black'}}>30 Días</td>
                          <td className="font-weight-bold" style={{border:'1px solid black'}}>Pago:</td>
                          <td style={{border:'1px solid black'}}>
                            {currentQuote.creditType === '100_FULL' ? '100% anticipo' : null}
                            {currentQuote.creditType === '30_DAYS' ? '30 días de crédito' : null}
                            {currentQuote.creditType === '50_50' ? '50% anticipo y 50% contra aviso de embarque' : null}
                            {currentQuote.creditType === '100_ADVICE_SHIPPING' ? '100% contra aviso de embarque' : null}
                          </td>
                        </tr>
                        <tr>
                          <td style={{border:'1px solid black'}} className="font-weight-bold">Atención:</td>
                          <td style={{border:'1px solid black'}}>{currentQuote.contact}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <Row className="mb-3 p-4">
                    <table border={1}>
                      <tr>
                        <td className="font-weight-bold" style={{border:'1px solid black', padding:8, fontSize:12}}>PARTIDA</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">CANTIDAD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">DESCRIPCION</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">TIEMPO DE ENTREGA</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">PRECIO UNITARIO USD</td>
                        <td style={{border:'1px solid black', padding:8, fontSize:12}} className="font-weight-bold">IMPORTE TOTAL USD</td>
                      </tr>
                      <tr>
                          <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>{currentQuote.part}</td>
                          <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>{currentQuote.qty}</td>
                          <td style={{border:'1px solid black', textAlign:'left', fontWeight:'bold', padding:8, maxWidth:450}}>
                            {currentQuote.valSetted && !currentQuote.valError ?
                            <>
                              {currentQuote.valveDescription}<br/>
                              Marca: {currentQuote.valveBrand}<br/>
                              Modelo: {currentQuote.valveType.replace("-","")}<br/>
                              Tamaño: {currentQuote.valveSize} pulgada(s)<br/>
                              {
                                currentQuote.bodyMaterial === "WCC" ?
                                  "Acero al carbon grado WCC"
                                :
                                  "Acero inoxidable grado CF8M"
                              }<br/>
                              Empaquetadura:&nbsp;
                                {currentQuote.valvePackage === 'PTFE' ? 'simple Teflon' : null}
                                {currentQuote.valvePackage === 'GRAPH' ? 'grafito' : null}
                                {currentQuote.valvePackage === 'DOBLE PTFE' ? 'simple grafito' : null}
                                {currentQuote.valvePackage === 'DOBLE GRAPH' ? 'doble grafito' : null}
                                {currentQuote.valvePackage === 'Enviro seal' ? 'Ambientales enviromental seals' : null}
                              <br/>
                              Conexión a proceso:&nbsp;
                                {currentQuote.valveRating === '150' ? 'ANSI CL 150 RF' : null}
                                {currentQuote.valveRating === '300' ? 'ANSI CL 300 RF' : null}
                                {currentQuote.valveRating === '600' ? 'ANSI CL 600 RF' : null}
                              <br/>
                                {currentQuote.valveTrim === '101' ? 
                                  <>Material del tapon: SST 416 HTn<br/>
                                    Material del asiento: SST 416 HT<br/>
                                    Material del retensor: 17-4PH SST<br/>
                                    Buje Guia: 17-4PH SST</> : null}
                                {currentQuote.valveTrim === '104' ? 
                                  <>Material del tapon: SST 316<br/>
                                  Material del asiento: SST 316<br/>
                                  Material del retensor: 17-4PH SST<br/>
                                  Buje Guia: 17-4PH SST</> : null}
                                {currentQuote.valveTrim === '127' ? 
                                  <>Material del tapon: S31600 w/CoCr-A seat and guide<br/>
                                  Material del asiento: S31600 w/CoCr-A seat<br/>
                                  Material del retensor: 17-4PH SST<br/>
                                  Buje Guia: CoCra</> : null}
                                {currentQuote.valveTrim === '128' ? 
                                  <>Material del tapon: S31600 w/CoCr-A seat<br/>
                                  Material del asiento: S31600 w/CoCr-A seat<br/>
                                  Material del retensor: 17-4PH SST<br/>
                                  Buje Guia: CoCra</> : null}
                                {currentQuote.valveTrim === '129' ? 
                                  <>Material del tapon: S31600<br/>
                                  Material del asiento: S31600<br/>
                                  Material del retensor: 17-4PH SST<br/>
                                  Buje Guia: CoCra</> : null}
                                {currentQuote.valveTrim === '137' ? 
                                  <>Material del tapon: S31600 w/CoCr-A seat and guide<br/>
                                  Material del asiento: S31600 w/CoCr-A seat<br/>
                                  Material del retensor: 17-4PH SST<br/>
                                  Buje Guia: CoCra</> : null}
                                {currentQuote.valveTrim === '139' ? 
                                  <>Material del tapon: S31600 w/CoCr-A seat<br/>
                                  Material del asiento: S31600 w/CoCr-A seat<br/>
                                  Material del retensor: 17-4PH SST<br/>
                                  Buje Guia: 17-4PH SST</> : null}
                                {currentQuote.valveTrim === '152' ? 
                                  <>Material del tapon: S31600 R30006 seat and tip<br/>
                                  Material del asiento: S31600<br/>
                                  Material del retensor: 17-4PH SST<br/>
                                  Buje Guia: 17-4PH SST</> : null}
                                {currentQuote.valveTrim === '1' ? 
                                  <>Material del tapon: SS416<br/>
                                  Material del asiento: SS416<br/>
                                  Caja caracterizadora: 17-4PH SST<br/>
                                  Tipo de asiento: Metal</> : null}
                                {currentQuote.valveTrim === '3' ? 
                                  <>Material del tapon: 316 SST CoCr-A (asiento y guia)<br/>
                                  Material del asiento: R30006<br/>
                                  Caja caracterizadora: R30006<br/>
                                  Tipo de asiento: Metal</> : null}
                                {currentQuote.valveTrim === '4' ? 
                                  <>Material del tapon: 316 SST<br/>
                                  Tipo de asiento: Metal<br/>
                                  Material del asiento: 316 SST<br/>
                                  Caja caracterizadora: 17-4PH SST</> : null}
                                {currentQuote.valveTrim === '27' ? 
                                  <>Material del tapon: 316 SST CoCr-A (asiento y guia)<br/>
                                  Material del asiento: R30006<br/>
                                  Caja caracterizadora:  316 SST ENC<br/>
                                  Tipo de asiento: Metal</> : null}
                                {currentQuote.valveTrim === '27C' ? 
                                  <>Material del tapon: 316 SST CoCr-A (asiento y guia)<br/>
                                  Material del asiento: R30006/SST 316 / PTFE<br/>
                                  Caja caracterizadora:  316 SST ENC<br/>
                                  Tipo de asiento: Teflon</> : null}
                                {currentQuote.valveTrim === '28' ? 
                                  <>Material del tapon: 316 SST CoCr-A (Seat)<br/>
                                  Material del asiento: R30006<br/>
                                  Caja caracterizadora: 316 SST ENC<br/>
                                  Tipo de asiento: Metal</> : null}
                                {currentQuote.valveTrim === '29' ? 
                                  <>Material del tapon: 316 SST<br/>
                                  Tipo de asiento: Metal<br/>
                                  Material del asiento: 316 SST<br/>
                                  Caja caracterizadora: 316 SST ENC</> : null}
                                {currentQuote.valveTrim === '37, 37H' ? 
                                  <>Material del tapon: 316 SST CoCr-A (asiento y guia)<br/>
                                  Material del asiento: R30006<br/>
                                  Caja caracterizadora: 17-4PH SST<br/>
                                  Tipo de asiento: Metal</> : null}
                                {currentQuote.valveTrim === '57' ? 
                                  <>Material del tapon: SS416<br/>
                                  Tipo de asiento: Metal<br/>
                                  Material del asiento: SS 316<br/>
                                  Caja caracterizadora: 17-4PH SST</> : null}
                                {currentQuote.valveTrim === '1C' ? 
                                  <>Material del tapon: SS416<br/>
                                  Material del asiento: SS416/PTFE<br/>
                                  Caja caracterizadora: 17-4PH SST<br/>
                                  Tipo de asiento: Teflon</> : null}
                                {currentQuote.valveTrim === '4C' ? 
                                  <>Material del tapon: 316 SST<br/>
                                  Tipo de asiento: Teflon<br/>
                                  Material del asiento: 316 SST / Teflon<br/>
                                  Caja caracterizadora: 17-4PH SST</> : null}
                                {currentQuote.valveTrim === '29C' ? 
                                  <>Material del tapon: 316 SST<br/>
                                  Tipo de asiento: Teflon<br/>
                                  Material del asiento: 316 SST/PTFE<br/>
                                  Caja caracterizadora: 316 SST ENC</> : null}
                              <br/>
                              Puerto de la valvula: {currentQuote.valvePort}<br/>
                              Tipo de bonete:&nbsp;
                              {currentQuote.valveBonet === 'STD' ? 'Plano estandar' : null}
                              {currentQuote.valveBonet === 'Ext1' ? 'Extension 1' : null}
                              {currentQuote.valveBonet === 'EXt2' ? 'Extension 2' : null}
                              <br/>
                              Caracteristica de flujo:&nbsp;
                              {currentQuote.valveChar === 'EQ' ? 'Igual porcentaje' : null}
                              {currentQuote.valveChar === 'Q.O' ? 'Apertura rapida' : null}
                              {currentQuote.valveChar === 'LIN' ? 'Lineal' : null}
                              <br/>
                              Código especial: {currentQuote.valveCustom}
                              <hr/>
                            </>
                            : null }

                            {currentQuote.actSetted && !currentQuote.actError ?
                            <>
                              {currentQuote.actDescription}<br/>
                              Marca: {currentQuote.actBrand}<br/>
                              Modelo: {currentQuote.actType.replace("-","")}<br/>
                              Tamaño: {currentQuote.actSize}<br/>
                              Señal de entrada:&nbsp;
                              {currentQuote.actRange === '6-30' ? 'Señal de entrada: 6-30 (0-33 psig)' : null}
                              {currentQuote.actRange === '3-15' ? 'Señal de entrada: 3-15 (0-18 ) Psig' : null}
                              <br/>
                              {currentQuote.actType === 'AT67-' ? 'A falla de aire Cierra' : null}
                              {currentQuote.actType === 'AT57-' ? 'A falla de aire abre' : null}
                              <br/>
                              {currentQuote.actIncludes !== null && currentQuote.actIncludes !== "" && currentQuote.actIncludes !== undefined ?
                                <>
                                Incluye:<br/>
                                <div class="docu-includes" dangerouslySetInnerHTML={{__html:currentQuote.actIncludes}}></div>
                                </> : null}
                              <hr/>
                            </>
                            : null}

                            {currentQuote.posSetted && !currentQuote.posError ?
                            <>
                              {currentQuote.posType === 'SRD991' || currentQuote.posType === 'SRD960' || currentQuote.posType === 'DVC6200' ? 'Posicionador digital' : null}
                              {currentQuote.posType === '1000L' || currentQuote.posType === '3582I' ? 'Posicionador electroneumatico' : null}
                              {currentQuote.posType === '1200l' ? 'Posicionador Neumático' : null}
                              <br/>
                              {currentQuote.posType === 'SRD991' || currentQuote.posType === 'SRD960' ? 'Marca: Schneider' : null}
                              {currentQuote.posType === '1000L' || currentQuote.posType === '1200l' ? 'Marca: CIESA VLS' : null}
                              {currentQuote.posType === '3582I' || currentQuote.posType === 'DVC6200' ? 'Marca: Fisher' : null}
                              <br/>
                              Modelo: {currentQuote.posType}<br/>
                              Señal de entrada:&nbsp;{
                                currentQuote.posCom === "Neumatico" ?
                                  'neumatica 3-15 psi (Calibrable a 6-30)'
                                : currentQuote.posCom
                              }<br/>
                              {currentQuote.posDia === "Diagnosticos avanzados" ?
                                  'Incluye Diagnosticos avanzados, Firma de la valvula, salud del I/P, Friccion de los empaques, Conteo de movimientos, diagnosito de sencibilidad y banda muerta.' : null }
                              {currentQuote.posDia === "Diagnosticos base" ?
                                  'Diagnosticos base, conteo de ciclos' : null }
                              {currentQuote.posDia === "N/A" ?
                                  'Calibrado de acuerdo a la carrera de la valvula' : null }
                              <br/>
                              {currentQuote.posOpc === "Feedback" ?
                                  'Incluye tarjeta de Feedback' : 'Resistente a la vibración'}
                              <br/>
                              Rango de ajuste: {currentQuote.posReg}
                              <br/>
                              {currentQuote.posIncludes !== undefined ?
                                <>
                                Incluye:<br/>
                                <div class="docu-includes" dangerouslySetInnerHTML={{__html:currentQuote.posIncludes}}></div>
                                </> : null}
                            </> : null}

                            {currentQuote.extras !== null && currentQuote.extras !== undefined && currentQuote.extras !== '' && currentQuote.extras.length > 0 ?
                              <>
                                EXTRAS:<br/>
                                {currentQuote.extras.map((extra)=>{
                                  return(
                                    <>
                                      &bull; {extra.name} (${extra.amount ? extra.amount.toLocaleString() : '0'} ya incluido en importe total)<br/>
                                      {extra.description}<br/>
                                    </>
                                  )
                                })}
                              </>
                            : null}
                        </td>
                        <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>{currentQuote.estimatedTime}</td>
                        <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>${currentQuote.subTotal !== null && currentQuote.subTotal !== undefined && currentQuote.subTotal !== '' ? currentQuote.subTotal.toLocaleString(2): ''}</td>
                        <td style={{border:'1px solid black',padding:8, alignContent:'start', fontWeight:'bold'}}>${currentQuote.total !== null && currentQuote.total !== undefined && currentQuote.total !== '' ? currentQuote.total.toLocaleString(2) : ''}</td>
                      </tr>
                    </table>
                  </Row>
                  <Row className="mb-3 p-3">
                    <p><b>1. PAGO DE DOLARES. </b>En caso de vernos favorecidos con su pedido, favor de
tomar en cuenta que el tipo de moneda de esta cotización son dólares americanos,
cuya paridad se tomará a la fecha de liquidación de los bienes.</p>
                    <p><b>2. IMPUESTO.</b> Los precios no incluyen el 16% de IVA, por lo que al facturar se
                    adicionará para ser cubierto por el cliente.</p>
                    <p><b>3. PAGO EN PESOS</b> En caso de vernos favorecidos con su pedido, favor de tomar
                    en cuenta que la moeda es en pesos mexicanos.</p>
                    <p><b>4. CANCELACION</b> En caso de cancelación se hará un cargo por el 50% de la orden de
                    compra</p>
                    <br/>
                    <br/>
                    <br/>
                    <h5><b>CONDICIONES COMERCIALES</b></h5>
                    <p>I. El tipo de moneda de la cotización son dólares norteamericanos, pagaderos en Moneda
                    Nacional al tipo de cambio a la fecha de liquidación de los bienes</p>
                    <p>II. La forma de pago de los bienes será:<b>
                      {currentQuote.creditType === '100_FULL' ? '100% anticipo' : null}
                      {currentQuote.creditType === '30_DAYS' ? '30 días de crédito' : null}
                      {currentQuote.creditType === '50_50' ? '50% anticipo y 50% contra aviso de embarque' : null}
                      {currentQuote.creditType === '100_ADVICE_SHIPPING' ? '100% contra aviso de embarque' : null}
                    </b></p>
                    <p>III. El tiempo de entrega de los bienes está indicado en la columna correspondiente y empezará a correr a partir de la aceptación de su pedido, y de la recepción de su anticipo correspondiente</p>
                    <p>IV. El lugar de entrega de los bienes será el sitio de instalación de los mismos.</p>
                    <p>V. La vigencia de la presente es de 30 días.</p>
                    <p>"VI. Garantía del equipo: Control e Instrumentación Empresarial S.A. de C.V.
 garantiza sus productos contra defectos, siempre y cuando estos sean usados bajo las condiciones especificadas para su aplicación. "
Esta es vigente durante un periodo de 12 meses en operación continua.</p>
                    <p>VII. Control e Instrumentación Empresarial S.A. de C.V. quien podrá cotizarles en caso de requerir, capacitación, puesta en marcha y arranque de los equipos.</p>
                    <p>VIII. Al facturar se agregará el 16% del IVA correspondiente.</p>
                    <p>IX. En caso de vernos favorecidos con su pedido, favor de anexar la copia de su Registro Federal de Causantes.</p>
                    <h6>Agradecemos su preferencia por nuestros productos.</h6>
                    <br/>
                    <br/>
                    <br/>
                    <h4 style={{textTransform:'uppercase'}}>{currentQuote.owner ? "ING. " + currentQuote.owner[0].name : "ING. Christian Cuatepotzo"}<br/>
                    DEPARTAMENTO DE VENTAS</h4>
                  </Row>
                </div>
              </CardBody>
            : null }
            </Col>
          </Row>
        </div>

        <div className="hidePrint" onClick={handlePrint} style={{position: 'fixed', fontWeight:'bold', background: 'black', padding: 15, borderRadius: 50, color: 'white', width: 60, height:60, right: 30, bottom: 30, cursor: 'pointer', zIndex:100}}>
          <Printer size={30} />
        </div>
      </Container>
    </div>
  );
};

export default Quote;
