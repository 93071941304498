import React from "react";

// dashboard
import Home from "../component/general/dashboard/home";
import Users from "../component/general/dashboard/users";
import Services from "../component/general/dashboard/services";
// import CalendarScreen from "../component/general/dashboard/calendar";
import Customer from "../component/general/dashboard/customer";
import Valves from "../component/general/dashboard/valves";
import Pediment from "../public/pediment";
import ValvesFix from "../component/general/dashboard/valvesFix";
import Inventory from "../component/general/dashboard/inventory";
import Cardex from "../component/general/dashboard/cardex";
import Quotes from "../component/general/dashboard/quotes";
import Quote from "../public/quote";
import Items from "../component/general/dashboard/items";
import PartsQuotes from "../component/general/dashboard/parts-quotes";
import QuotePart from "../public/quote-part";
import PurchaseOrders from "../component/general/dashboard/purchase-orders";
import Suppliers from "../component/general/dashboard/suppliers";
import Bom from "../public/bom";
import Purchase from "../public/purchase";
import FollowItems from "../component/general/dashboard/logistic";
import Logistic from "../component/general/dashboard/logistic";
import Warranty from "../component/general/dashboard/warranty";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/inicio`,
    Component: <Home />,
  },
  {
    path: `${process.env.PUBLIC_URL}/servicios`,
    Component: <Services />,
  },
  {
    path: `${process.env.PUBLIC_URL}/valvulas`,
    Component: <Valves />,
  },
  {
    path: `${process.env.PUBLIC_URL}/reparacion-valvulas`,
    Component: <ValvesFix />,
  },
  {
    path: `${process.env.PUBLIC_URL}/usuarios`,
    Component: <Users />,
  },
  {
    path: `${process.env.PUBLIC_URL}/clientes`,
    Component: <Customer />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pediment`,
    Component: <Pediment />,
  },
  {
    path: `${process.env.PUBLIC_URL}/quote`,
    Component: <Quote />,
  },
  {
    path: `${process.env.PUBLIC_URL}/quote-part`,
    Component: <QuotePart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/inventario`,
    Component: <Inventory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/movimientos`,
    Component: <Cardex />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cotizaciones`,
    Component: <Quotes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/cotizaciones-partes`,
    Component: <PartsQuotes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/prices`,
    Component: <Items />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ordenes-compra`,
    Component: <PurchaseOrders />,
  },
  {
    path: `${process.env.PUBLIC_URL}/proveedores`,
    Component: <Suppliers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bom`,
    Component: <Bom />,
  },
  {
    path: `${process.env.PUBLIC_URL}/purchase`,
    Component: <Purchase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/logistica`,
    Component: <Logistic />,
  },
  {
    path: `${process.env.PUBLIC_URL}/garantias`,
    Component: <Warranty />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/calendario`,
  //   Component: <CalendarScreen />,
  // },
];
